import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Links from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import SaveIcon from '@material-ui/icons/Save';
import { useHistory } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import { url } from "../Componentes/baseurl";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            Todos Los Derechos Reservados <br></br>
            TECMMAS S.A.S {' '}
            {new Date().getFullYear()}
            {'.'}
            <br></br>
            <Links color="inherit" target="_blank" href="https://tecmmas.com/sitio/">
                www.tecmmas.com
            </Links>
            <br></br>
            <label style={{ color: "red" }}>Realese 1.0.1</label>

        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(18),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignContent: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.info.dark,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    },
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default function Cambiocontrasena() {
    const MySwal = withReactContent(Swal);
    let histori = useHistory();
    const classes = useStyles();
    const [cargar, setCargar] = useState(false);
    const [data, setData] = useState({
        contrasena: ""
    });

    const handleInputChange = (ev) => {
        setData({
            ...data,
            [ev.target.name]: ev.target.value
        })
    }
    const enviarDatos = (ev) => {
        ev.preventDefault();
        const datos = {
            password: data.contrasena,
            idactor: localStorage.getItem('idactor'),
            function: 'updateContra'
        }
        let validate = true;
        let mesaje = "";
        if (data.contrasena.length < 6 || data.contrasena == null) {
            mesaje = mesaje + '<br />Campo contraseña es obligatorio.<br><label style= "color: red"> La contraseña debe contar con minimo 6 caracteres.</label>'
            validate = false;
        }
        if (!validate) {
            MySwal.fire({
                icon: 'info',
                title: '<div style="font-size: 24px">Verificación de datos</div>',
                html: '<div style="color: red; font-size: 18px">Existen datos incompletos:</div> <div style="font-size: 14px">' + mesaje + '</div>',
                confirmButtonColor: 'green',
                footer: '<div style="color: red">Todos los campos son obligatorios.</div>'
            })
            setCargar(false);
        } else {
            fetch(url + "Clogin",
                {
                    method: "POST",
                    body: JSON.stringify(datos),
                    headers: {
                        'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
                        'Content-Type': 'application/json'
                    },
                })
                .then(respuesta => respuesta.json())
                .then((rta) => {
                    console.log(rta);
                    if (rta == 1) {
                        setCargar(false);
                        ev.target.reset();
                        MySwal.fire({
                            position: 'center',
                            icon: 'info',
                            text: 'Contraseña actualizada',
                            showConfirmButton: true,
                            confirmButtonColor: '#3085d6',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                histori.push("/");
                            } else {
                                histori.push("/");
                            }
                        })
                    }
                })
                .catch(error => {
                    console.log(error.message);
                    if (error) {
                        setCargar(false);
                        MySwal.fire({
                            position: 'center',
                            icon: 'error',
                            text: 'Problemas en el registro: ' + error.message,
                            showConfirmButton: true,
                            confirmButtonColor: '#3085d6',
                            // timer: 1500
                        })
                    }
                });
        }
    }

    return (
        <Container component="main" maxWidth="xs" >
            <CssBaseline />
            <div className={classes.paper}>
                <img alt='some value' src={"http://sigc.tecmmas.com/Imagenes/logo.png"} />
                <form className={classes.form} noValidate autoComplete="off" onSubmit={enviarDatos}>
                    <Typography align="center" color="primary" variant="body1">
                        Es hora de cambiar la contraseña <br /> {localStorage.getItem('user')}
                    </Typography>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="contrasena"
                        label="Contraseña Nueva"
                        type="password"
                        id="contrasena"
                        value={data.contrasena}
                        autoComplete="current-password"
                        onChange={handleInputChange}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        {cargar ? <CircularProgress style={{ marginRight: "10px", color: "white" }} /> : <SaveIcon style={{ marginRight: "8px" }} />}
                        Actualizar
                    </Button>
                    <Grid container>
                        <Grid item>
                            <Link to={"/"} style={{ textDecoration: "none", color: "#3991DA" }}>
                                {"Regresar"}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={4}>
                <Copyright />
            </Box>
        </Container>

    );
}