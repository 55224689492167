import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { Grid } from '@material-ui/core';
import Menu from "../Componentes/Menu";
import Headeditfuc from "../Componentes/Headeditfuc";



const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
}));

function Fuceditventas(props) {
    let histori = useHistory();
    let user = localStorage.getItem("user");
    let consecutivo = localStorage.getItem("consecutivo");
    if (user == null || user == "") {
        histori.push("/");
    }
    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <Menu />
    );

    const container = window !== undefined ? () => window().document.body : undefined;
    // carga de usuarios al auto complete 



    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        Editar Fuc - {consecutivo}
                    </Typography>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Headeditfuc />
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} style={{ boxShadow: "0px 0px 1px  black", marginTop: "24px", width: "100%" }}>
                        <Grid container spacing={1} style={{ justifyContent: "center", display: "flex" }} >
                            <Grid item xs={12} sm={12} >
                                <Typography variant="subtitle1" align="center" style={{ marginTop: '23px' }}>
                                    <strong style={{color: "red"}} >Este fuc es una orden de servicio, por lo tanto debe asignarla a la persona que va a ejecutar la laboral que se requiere.</strong>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </main >
        </div >
    );
}

Fuceditventas.propTypes = {
    window: PropTypes.func,
};

export default Fuceditventas;
