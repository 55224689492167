import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { Grid, Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Menu from "../Componentes/Menu";
import Archivos from '../Componentes/Archivos';
import Headeditfuc from "../Componentes/Headeditfuc";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import CloseIcon from '@material-ui/icons/Close';
import { url } from "../Componentes/baseurl";
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';



const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },


}));

function Formatohoras(props) {
    const [cargar, setCargar] = useState(false);
    const [value, setValue] = useState("Guardar");
    let histori = useHistory();
    let user = localStorage.getItem("user");
    let consecutivo = localStorage.getItem("consecutivo");
    if (user == null || user == "") {
        histori.push("/");
    }
    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <Menu />
    );

    const container = window !== undefined ? () => window().document.body : undefined;
    // carga de usuarios al auto complete 
    const MySwal = withReactContent(Swal);

    useEffect(() => {
        getInfofuc();
        getUsuarios();
        setInterval(() => {
            navigator.geolocation.getCurrentPosition(onSuccess, onError);
        }, 5000);
    }, [])
    const [localizacion, setLocalizacion] = useState("");
    var onSuccess = function (position) {
        var localizacion = position.coords.latitude + ',' + position.coords.longitude
        setLocalizacion(localizacion);
    }
    function onError(error) {
        console.log('code: ' + error.code + '\n' +
            'message: ' + error.message + '\n');
    }

    //usuarios 
    const [getusuarios, setGetusuarios] = useState([]);
    const getUsuarios = async () => {
        let idrol = localStorage.getItem("idrol");
        // debugger
        const res = await fetch(url + "Clogin", {
            method: "POST",
            body: JSON.stringify({ idrol: idrol, function: "getUsuarios" }),
            headers: {
                'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
                'Content-Type': 'application/json',
            },
        })
        const getusuarios = await res.json();
        setGetusuarios(getusuarios.Usuarios);
    }

    //consultainformacion guardada
    const [infoFuc, setInfoFuc] = useState([]);
    const [infoHoras, setInfoHoras] = useState([]);
    const getInfofuc = async () => {
        // debugger
        const res = await fetch(url + "Formatos/FormatoHoras/Choras", {
            method: "POST",
            body: JSON.stringify({ idfuc: localStorage.getItem('idfuc'), function: "getInfoFucHoras" }),
            headers: {
                'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
                'Content-Type': 'application/json',
            },
        })
        const info = await res.json();
        setInfoFuc(info.infofuc);
        setInfoHoras(info.actividades);
    }

    // info valor recibido
    const [tec1, setTec1] = useState({
        fechainiciotec1: "",
        horatec1: "",
        descripciontec1: "",
    });

    const GuardarInfoTec1 = (ev) => {
        setTec1({
            ...tec1,
            [ev.target.name]: ev.target.value
        })
    }
    const [ArregloTec1, setArregloTec1] = useState([]);
    const [contador2, setContador2] = useState(1);
    const Guardartec1 = () => {
        const dataTec1 = {
            contador2: contador2,
            fechainicio: tec1.fechainiciotec1,
            horainicio: tec1.horatec1,
            descripcion: tec1.descripciontec1,
            idactor: localStorage.getItem('idactor'),
            personal: '1'
        }
        var valid = true;
        var mesaje = "";
        if (dataTec1.fechainicio.length <= 0 || dataTec1.fechainicio == null) {
            valid = false;
            mesaje = mesaje + "<br> Fecha inicio obligatoria."
        }
        // if (dataTec1.horainicio.length <= 0 || dataTec1.horainicio == null) {
        //     valid = false;
        //     mesaje = mesaje + "<br> Hora inicio obligatoria."
        // }
        if (dataTec1.descripcion.length <= 0 || dataTec1.descripcion == null) {
            valid = false;
            mesaje = mesaje + "<br> Descripción de actividad obligatoria."
        }
        if (!valid) {
            mesajeerror(mesaje);
        } else {
            setArregloTec1([...ArregloTec1, dataTec1]);
            setContador2(contador2 + 1);
        }
    }

    const deleteTec1 = (id) => {
        setArregloTec1(ArregloTec1.filter(contador2 => contador2.contador2 !== id))
    }

    //info tec2

    const [tec2, setTec2] = useState({
        fechainiciotec2: "",
        horatec2: "",
        descripciontec2: "",
    });

    const GuardarInfoTec2 = (ev) => {
        setTec2({
            ...tec2,
            [ev.target.name]: ev.target.value
        })
    }
    const [ArregloTec2, setArregloTec2] = useState([]);
    const [contador, setContador] = useState(1);
    const Guardartec2 = () => {
        const dataTec2 = {
            contador: contador,
            fechainicio: tec2.fechainiciotec2,
            horainicio: tec2.horatec2,
            descripcion: tec2.descripciontec2,
            idactor: localStorage.getItem('idactor'),
            personal: '2'
        }
        var valid = true;
        var mesaje = "";
        if (dataTec2.fechainicio.length <= 0 || dataTec2.fechainicio == null) {
            valid = false;
            mesaje = mesaje + "<br> Fecha inicio obligatoria."
        }
        // if (dataTec2.horainicio.length <= 0 || dataTec2.horainicio == null) {
        //     valid = false;
        //     mesaje = mesaje + "<br> Hora inicio obligatoria."
        // }
        if (dataTec2.descripcion.length <= 0 || dataTec2.descripcion == null) {
            valid = false;
            mesaje = mesaje + "<br> Descripción de actividad obligatoria."
        }
        if (!valid) {
            mesajeerror(mesaje);
        } else {
            setArregloTec2([...ArregloTec2, dataTec2]);
            setContador(contador + 1);
        }
    }

    const deleteTec2 = (id) => {
        setArregloTec2(ArregloTec2.filter(contador => contador.contador !== id))
    }

    function mesajeerror(mesaje) {
        MySwal.fire({
            icon: 'info',
            title: '<div style="font-size: 24px">Verificación de datos</div>',
            html: '<div style="color: red; font-size: 18px">Existen datos incompletos:</div> <div style="font-size: 14px">' + mesaje + '</div>',
            confirmButtonColor: 'green',
            footer: '<div style="color: red">Todos los campos son obligatorios.</div>'
        })
    }

    function cancelar() {
        histori.push("/Principal");
    }

    //enviar datos 

    const [personal1, setPersonal1] = useState("0");
    const [personal2, setPersonal2] = useState("0");
    const [jefepista, setJefepista] = useState("0");

    const Personal1 = (value) => {
        setPersonal1(value.idactor);
    }
    const Personal2 = (value) => {
        setPersonal2(value.idactor);
    }
    const Jefepista = (value) => {
        setJefepista(value.idactor);
    }
    //envio datos
    const [data, setData] = useState({
        recomendaciones: "",
        atencionpt: "",
        atencionpe: "",
        presentaciont: "",
        calidad: "",
        satisfaccion: "",
    });

    const inputRegistro = (ev) => {
        setData({
            ...data,
            [ev.target.name]: ev.target.value
        })
    }

    const enviarDatos = (ev) => {
        ev.preventDefault();
        setCargar(true);
        setValue("Por favor espere...");
        var valid = true;
        var mesaje = "";
        const desarrollofuc = localStorage.getItem("iddesarrollofuc");
        let funct = "";
        if (desarrollofuc == null || desarrollofuc == "") {
            funct = "saveFormatoHoras";
        } else {
            funct = "UpdateFormatoHoras";
        }
        const datos = {
            function: funct,
            document: localStorage.getItem('document'),
            image: localStorage.getItem('image'),
            arrtec1: ArregloTec1,
            arrtec2: ArregloTec2,
            personal1: personal1,
            personal2: personal2,
            jefepista: jefepista,
            recomendaciones: data.recomendaciones,
            desarrollofuc: desarrollofuc,
            calidad: data.calidad,
            satisfaccion: data.satisfaccion,
            atencionpt: data.atencionpt,
            atencionpe: data.atencionpe,
            presentaciont: data.presentaciont,
            localizacion: localizacion,
            idtipofuc: localStorage.getItem('idtipofuc'),
            idfuc: localStorage.getItem('idfuc'),
        }
        if (datos.localizacion.length <= 0 || datos.localizacion == null) {
            valid = false;
            mesaje = mesaje + "<br> Datos imcompletos, por favor verifique que la ubicacion esta habilitada."
        }
        if (!valid) {
            mesajeerror(mesaje);
            setCargar(false);
            setValue("Guardar");
        } else {
            fetch(url + "Formatos/FormatoHoras/Choras",
                {
                    method: "POST",
                    body: JSON.stringify(datos),
                    headers: {
                        'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
                        'Content-Type': 'application/json'
                    },
                })
                .then(respuesta => respuesta.json())
                .then((rta) => {
                    if (rta == 1) {
                        setCargar(false);
                        setValue("Guardar");
                        MySwal.fire({
                            position: 'center',
                            icon: 'info',
                            text: 'La información se guardo con exito.',
                            showConfirmButton: true,
                            confirmButtonColor: '#3085d6',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                histori.push("/Principal");
                            } else {
                                histori.push("/Principal");
                            }
                        })
                    } else {
                        setCargar(false);
                        setValue("Guardar");
                        MySwal.fire({
                            position: 'center',
                            icon: 'error',
                            text: 'Problemas al guardar información.' + rta,
                            showConfirmButton: true,
                            confirmButtonColor: '#3085d6',
                        });
                    }
                }).catch(error => {
                    setCargar(false);
                    setValue("Guardar");
                    MySwal.fire({
                        position: 'center',
                        icon: 'error',
                        text: 'Error en servidor ' + error.message,
                        showConfirmButton: true,
                        confirmButtonColor: '#3085d6',
                    });
                });
        }
    }

    //acordion 
    const [acorTec1, setAcorTec1] = useState(false);

    const handletec1 = (panel) => (event, isExpanded) => {
        setAcorTec1(isExpanded ? panel : false);
    };
    const [acorTec2, setAcorTec2] = useState(false);

    const handletec2 = (panel) => (event, isExpanded) => {
        setAcorTec2(isExpanded ? panel : false);
    };
    const [acorInfo, setAcorInfo] = useState(false);

    const handleinfo = (panel) => (event, isExpanded) => {
        setAcorInfo(isExpanded ? panel : false);
    };

    //fecha
    const values = {
        someDate: new Date().toISOString().substring(0, 10)
    };

    //actualizar fechafinal-horafinal
    const fecha = useRef(null);
    const horainput = useRef(null);

    const updateAct = (e, idhoras, index, name) => {
        let valor = [];
        valor[index] = e.target.value;
        let dat = valor.join();
        fetch(url + "Formatos/FormatoHoras/Choras",
            {
                method: "POST",
                body: JSON.stringify({ function: "updateActividad", id: idhoras, valor: dat, localizacion: localizacion, name: name }),
                headers: {
                    'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
                    'Content-Type': 'application/json'
                },
            })
            .then(respuesta => respuesta.json())
            .then((rta) => {
                if (rta == 1) {
                    getInfofuc();
                }
            })
            .catch(error => {
                setCargar(false);
                setValue("Guardar");
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    text: 'Error en servidor ' + error.message,
                    showConfirmButton: true,
                    confirmButtonColor: '#3085d6',
                });
            });

    }

    //table
    function createData(name, option) {
        return { name, option };
    }

    const rows = [
        createData('Atención del personal que se acercó a sus instalaciones.',
            <select
                id="atencionpt"
                name="atencionpt"
                onChange={inputRegistro}
                value={data.atencionpt}>
                <option value="0" disabled>0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">9</option>
                <option value="10">10</option>
            </select>
        ),
        createData('Atención del personal que lo atendió al momento de solicitar el servicio.', <select
            id="atencionpe"
            name="atencionpe"
            onChange={inputRegistro}
            value={data.atencionpe}>
            <option value="0" disabled>0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">9</option>
            <option value="10">10</option>
        </select>),
        createData('Presentación del personal.', <select
            id="presentaciont"
            name="presentaciont"
            onChange={inputRegistro}
            value={data.presentaciont}>
            <option value="0" disabled>0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">9</option>
            <option value="10">10</option>
        </select>),
        createData('Calidad del servicio.', <select
            id="calidad"
            name="calidad"
            onChange={inputRegistro}
            value={data.calidad}>
            <option value="0" disabled>0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">9</option>
            <option value="10">10</option>
        </select>),
        createData('Satisfacción del servicio', <select
            id="satisfaccion"
            name="satisfaccion"
            onChange={inputRegistro}
            value={data.satisfaccion}>
            <option value="0" disabled>0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">9</option>
            <option value="10">10</option>
        </select>),
    ];

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        Editar Fuc - {consecutivo}
                    </Typography>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Headeditfuc />
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} style={{ boxShadow: "0px 0px 1px  black", marginTop: "24px", width: "100%" }}>
                        <form className={classes.form} autoComplete="off" onSubmit={enviarDatos}>
                            <Grid container spacing={1} style={{ justifyContent: "center", display: "flex" }} >
                                <Grid item xs={12} sm={12} >
                                    <Typography variant="subtitle1" align="center">
                                        <strong style={{ marginLeft: "24px" }}>Actividades Personal 1</strong>
                                    </Typography>
                                    <Grid container spacing={1} >
                                        <Grid item xs={12} sm={6} md={6} lg={4} style={{ marginTop: "8px" }}>
                                            <Box>
                                                <TextField
                                                    variant="outlined"
                                                    inputProps={{ min: (values.someDate) }}
                                                    fullWidth
                                                    id="date"
                                                    label="Fecha inicio"
                                                    type="Date"
                                                    name="fechainiciotec1"
                                                    format="yyyy-MM-dd"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={GuardarInfoTec1}
                                                    value={tec1.fechainicio}
                                                />
                                            </Box>
                                        </Grid>
                                        {/* <Grid item xs={12} sm={6} md={4} lg={2} style={{ marginTop: "8px" }}>
                                            <Box>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    id="time"
                                                    label="Alarm clock"
                                                    type="time"
                                                    name="horatec1"
                                                    defaultValue="07:30"
                                                    onChange={GuardarInfoTec1}
                                                    value={tec1.horatec1}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        step: 300, // 5 min
                                                    }}
                                                />
                                            </Box>
                                        </Grid> */}
                                        <Grid item xs={12} sm={12} md={4} lg={8} style={{ marginTop: "10px" }}>
                                            <Box >
                                                <TextareaAutosize name="descripciontec1" id="descripciontec1" onChange={GuardarInfoTec1} style={{ width: "100%" }} aria-label="minimum height" rowsMin={3} placeholder="Describa la actividad realizada." />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} style={{ justifyContent: "center", display: "flex", marginTop: "10px" }} >
                                            <Box >
                                                <Button onClick={() => (Guardartec1())} style={{ backgroundColor: "green", color: "white", textAlign: "center" }}><PlaylistAddIcon style={{ marginRight: "8px" }} />Agregar</Button>
                                            </Box>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            {
                                                ArregloTec1.map(item => (
                                                    <Grid item xs={12} sm={12} md={12} lg={3} key={Math.random()}>
                                                        <Card style={{ boxShadow: "0px 0px 1px  black" }}>
                                                            <CardActionArea>
                                                                <CardContent>
                                                                    <Typography gutterBottom style={{ fontSize: "15px" }}>
                                                                        <strong>{item.material}</strong>
                                                                    </Typography>
                                                                    <Typography variant="body2" style={{ color: "black" }} component="p">
                                                                        <strong>Fecha inicio: </strong>{item.fechainicio}<br />
                                                                        {/* <strong>Hora inicio: </strong>{item.horainicio} <br /> */}
                                                                        <strong>Descripcion: </strong> <br />
                                                                        {item.descripcion}
                                                                    </Typography>
                                                                    <Grid item xs={12} sm={12} style={{ justifyContent: "center", display: "flex" }} >
                                                                        <IconButton aria-label="Eliminar" title="Eliminar" style={{ color: "red" }}>
                                                                            <CloseIcon style={{ height: "35px", width: "42px" }} onClick={(ev) => deleteTec1(item.contador2)} />
                                                                        </IconButton>
                                                                    </Grid>
                                                                </CardContent>
                                                            </CardActionArea>
                                                        </Card>
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <Accordion expanded={acorTec1 === 'panel1'} onChange={handletec1('panel1')}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                    style={{ backgroundColor: "#FCF3CF" }}
                                                >
                                                    <Typography style={{ color: "black" }}>Actividades Personal 1</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                        <Grid container spacing={1}>
                                                            {
                                                                infoHoras.map((item, index) => (
                                                                    item.personal == '1' ?
                                                                        <Grid item xs={12} sm={12} md={12} lg={3} key={Math.random()}>
                                                                            <Card style={{ boxShadow: "0px 0px 1px  black" }}>
                                                                                <CardActionArea>
                                                                                    <CardContent>
                                                                                        <Typography gutterBottom style={{ fontSize: "15px" }}>
                                                                                            <strong>{item.material}</strong>
                                                                                        </Typography>
                                                                                        <Typography variant="body2" style={{ color: "black" }} component="p">
                                                                                            <strong>Fecha inicio: </strong>{item.fechainicio}<br />
                                                                                            {/* <strong>Hora inicio: </strong>{item.horainicio} <br /> */}
                                                                                            <strong>Descripcion: </strong> <br />
                                                                                            {item.descripcion}<br />
                                                                                            <strong>Fecha final: </strong>{item.fechafinal} <br />
                                                                                            {
                                                                                                item.fechafinal == null ?
                                                                                                    <input type="date" name={index} min={values.someDate} onBlur={(e) => updateAct(e, item.idhoras, index, 'fecha')} />
                                                                                                    : ""
                                                                                            }

                                                                                            {/* <strong>Hora final: </strong>{item.horafinal} <br />
                                                                                            <input type="time" name={index} onBlur={(e) => updateAct(e, item.idhoras, index, 'hora')} /><br /> */}
                                                                                        </Typography>
                                                                                    </CardContent>
                                                                                </CardActionArea>
                                                                            </Card>
                                                                        </Grid>
                                                                        : ""
                                                                ))
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <Typography variant="subtitle1" align="center" >
                                                <strong style={{ marginLeft: "24px" }}>Actividades Personal 2</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid container spacing={1} >
                                            <Grid item xs={12} sm={6} md={6} lg={4} style={{ marginTop: "8px" }}>
                                                <Box>
                                                    <TextField
                                                        variant="outlined"
                                                        inputProps={{ min: (values.someDate) }}
                                                        fullWidth
                                                        id="date"
                                                        label="Fecha inicio"
                                                        type="Date"
                                                        name="fechainiciotec2"
                                                        format="yyyy-MM-dd"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onChange={GuardarInfoTec2}
                                                        value={tec2.fechainicio}
                                                    />
                                                </Box>
                                            </Grid>
                                            {/* <Grid item xs={12} sm={6} md={4} lg={2} style={{ marginTop: "8px" }}>
                                                <Box>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        id="time"
                                                        label="Alarm clock"
                                                        type="time"
                                                        name="horatec2"
                                                        defaultValue="07:30"
                                                        onChange={GuardarInfoTec2}
                                                        value={tec2.horatec2}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            step: 300, // 5 min
                                                        }}
                                                    />
                                                </Box>
                                            </Grid> */}
                                            <Grid item xs={12} sm={12} md={4} lg={8} style={{ marginTop: "10px" }}>
                                                <Box >
                                                    <TextareaAutosize name="descripciontec2" id="descripciontec2" onChange={GuardarInfoTec2} style={{ width: "100%" }} aria-label="minimum height" rowsMin={3} placeholder="Describa la actividad realizada." />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={12} style={{ justifyContent: "center", display: "flex", marginTop: "10px" }} >
                                                <Box >
                                                    <Button onClick={() => (Guardartec2())} style={{ backgroundColor: "green", color: "white", textAlign: "center" }}><PlaylistAddIcon style={{ marginRight: "8px" }} />Agregar</Button>
                                                </Box>
                                            </Grid>
                                            {
                                                ArregloTec2.map(item => (
                                                    <Grid item xs={12} sm={12} md={12} lg={3} key={Math.random()}>
                                                        <Card style={{ boxShadow: "0px 0px 1px  black" }}>
                                                            <CardActionArea>
                                                                <CardContent>
                                                                    <Typography gutterBottom style={{ fontSize: "15px" }}>
                                                                        <strong>{item.material}</strong>
                                                                    </Typography>
                                                                    <Typography variant="body2" style={{ color: "black" }} component="p">
                                                                        <strong>Fecha inicio: </strong>{item.fechainicio}<br />
                                                                        {/* <strong>Hora inicio: </strong>{item.horainicio} <br /> */}
                                                                        <strong>Descripcion: </strong> <br />
                                                                        {item.descripcion}
                                                                    </Typography>
                                                                    <Grid item xs={12} sm={12} style={{ justifyContent: "center", display: "flex" }} >
                                                                        <IconButton aria-label="Eliminar" title="Eliminar" style={{ color: "red" }}>
                                                                            <CloseIcon style={{ height: "35px", width: "42px" }} onClick={(ev) => deleteTec2(item.contador)} />
                                                                        </IconButton>
                                                                    </Grid>
                                                                </CardContent>
                                                            </CardActionArea>
                                                        </Card>
                                                    </Grid>
                                                ))
                                            }
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Accordion expanded={acorTec2 === 'panel1'} onChange={handletec2('panel1')}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1bh-content"
                                                        id="panel1bh-header"
                                                        style={{ backgroundColor: "#FCF3CF" }}
                                                    >
                                                        <Typography style={{ color: "black" }}>Actividades Personal 2</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                            <Grid container spacing={1}>
                                                                {
                                                                    infoHoras.map((item, index) => (
                                                                        item.personal == '2' ?
                                                                            <Grid item xs={12} sm={12} md={12} lg={3} key={Math.random()}>
                                                                                <Card style={{ boxShadow: "0px 0px 1px  black" }}>
                                                                                    <CardActionArea>
                                                                                        <CardContent>
                                                                                            <Typography gutterBottom style={{ fontSize: "15px" }}>
                                                                                                <strong>{item.material}</strong>
                                                                                            </Typography>
                                                                                            <Typography variant="body2" style={{ color: "black" }} component="p">
                                                                                                <strong>Fecha inicio: </strong>{item.fechainicio}<br />
                                                                                                {/* <strong>Hora inicio: </strong>{item.horainicio} <br /> */}
                                                                                                <strong>Descripcion: </strong> <br />
                                                                                                {item.descripcion}<br />
                                                                                                <strong>Fecha final: </strong>{item.fechafinal} <br />
                                                                                                {item.fechafinal == null ?
                                                                                                    <input type="date" name={index} min={values.someDate} onBlur={(e) => updateAct(e, item.idhoras, index, 'fecha')} />
                                                                                                    : ""
                                                                                                }

                                                                                                {/* <strong>Hora final: </strong>{item.horafinal} <br />
                                                                                                <input type="time" name={index} onBlur={(e) => updateAct(e, item.idhoras, index, 'hora')} /><br /> */}
                                                                                            </Typography>
                                                                                        </CardContent>
                                                                                    </CardActionArea>
                                                                                </Card>
                                                                            </Grid>
                                                                            : ""
                                                                    ))
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "5px" }}>
                                <Typography variant="subtitle1" align="center" >
                                    <strong style={{ marginLeft: "24px" }}>Recomendaciones</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "10px" }}>
                                <Box >
                                    <TextareaAutosize name="recomendaciones" id="recomendaciones" onChange={inputRegistro} style={{ width: "100%" }} aria-label="minimum height" rowsMin={3} placeholder="Recomendaciones." />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography variant="subtitle1" align="center" >
                                    <strong style={{ marginLeft: "24px" }}>Evaluación de servicio</strong>
                                </Typography>
                                <Typography variant="subtitle2" align="left" >
                                    <strong style={{ color: "blue" }}>Califique de 1-10 donde 10 es totalmente satisfecho con la atención prestada.</strong>
                                </Typography>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    <TableCell align="center"></TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row) => (
                                                    <TableRow key={row.name}>
                                                        <TableCell component="th" scope="row">
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell align="center">{row.option}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography variant="subtitle1" align="center" >
                                    <strong style={{ marginLeft: "24px" }}>Registro personal</strong>
                                </Typography>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} md={6} lg={4}>
                                    <Box >
                                        <Autocomplete
                                            options={getusuarios}
                                            getOptionLabel={(option) => option.nombre}
                                            id="responsable"
                                            name="responsable"
                                            onChange={(ev, value) => Personal1(value)}
                                            renderInput={(params) => <TextField {...params} label="Personal 1" margin="normal" variant="outlined" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={4}>
                                    <Box >
                                        <Autocomplete
                                            options={getusuarios}
                                            getOptionLabel={(option) => option.nombre}
                                            id="responsable"
                                            name="responsable"
                                            onChange={(ev, value) => Personal2(value)}
                                            renderInput={(params) => <TextField {...params} label="Personal 2" margin="normal" variant="outlined" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={4}>
                                    <Box >
                                        <Autocomplete
                                            options={getusuarios}
                                            getOptionLabel={(option) => option.nombre}
                                            id="responsable"
                                            name="responsable"
                                            onChange={(ev, value) => Jefepista(value)}
                                            renderInput={(params) => <TextField {...params} label="Jefe pista" margin="normal" variant="outlined" />}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "5px" }}>
                                <Archivos />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "5px" }}>
                                <Accordion expanded={acorInfo === 'panel1'} onChange={handleinfo('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        style={{ backgroundColor: "#AED6F1" }}
                                    >
                                        <Typography style={{ color: "black" }}>Información guardada</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <Grid container spacing={1}>
                                                {
                                                    infoFuc.map(item => (
                                                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ boxShadow: "0px 0px 1px  black" }} key={Math.random()} >
                                                            <Grid container spacing={1} style={{ marginTop: "8px" }} >
                                                                <Grid item xs={12} sm={6} md={4} lg={6}>
                                                                    <strong style={{ marginRight: "8px" }}>Recomendaciones: </strong><br />{item.recomendaciones}
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={4} lg={6}>
                                                                    <strong style={{ marginRight: "8px" }}>Calidad del servicio: </strong><br></br>{item.calidad}
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={4} lg={6}>
                                                                    <strong style={{ marginRight: "8px" }}>Satisfacciòn del servivio: </strong><br></br>{item.satisfaccion}
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={4} lg={6}>
                                                                    <strong style={{ marginRight: "8px" }}>Atención personal: </strong><br></br>{item.atencionpt}
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={4} lg={6}>
                                                                    <strong style={{ marginRight: "8px" }}>Atención personal empresa: </strong><br></br>{item.atencionpe}
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={4} lg={6}>
                                                                    <strong style={{ marginRight: "8px" }}>Presentacion del personal: </strong><br></br>{item.presentaciont}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ))
                                                }
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid container spacing={2} style={{ marginTop: "8px" }} >
                                <Grid item xs={6} sm={6}>
                                    <Box>
                                        <Button fullWidth style={{ backgroundColor: "red", color: "white", textAlign: "center" }} onClick={cancelar}><CloseIcon style={{ marginRight: "8px" }} />Cancelar</Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Box>
                                        <Button fullWidth type="submit" style={{ backgroundColor: "#3386FF", color: "white", textAlign: "center" }}> {cargar ? <CircularProgress style={{ marginRight: "10px", color: "white", }} /> : <SaveIcon style={{ marginRight: "8px" }} />} {value}</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </main >
        </div >
    );
}

Formatohoras.propTypes = {
    window: PropTypes.func,
};

export default Formatohoras;
