import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { Grid, Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Menu from "../Componentes/Menu";
import Headeditfuc from "../Componentes/Headeditfuc";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CloseIcon from '@material-ui/icons/Close';
import { url } from "../Componentes/baseurl";
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';
import Archivos from '../Componentes/Archivos';
import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },

}));

function Fuceditmantenimiento(props) {
    const [cargar, setCargar] = useState(false);
    const [value, setValue] = useState("Guardar");
    let histori = useHistory();
    let user = localStorage.getItem("user");
    let consecutivo = localStorage.getItem("consecutivo");
    if (user == null || user == "") {
        histori.push("/");
    }
    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <Menu />
    );

    const container = window !== undefined ? () => window().document.body : undefined;
    // carga de usuarios al auto complete 
    const MySwal = withReactContent(Swal);

    useEffect(() => {
        getMateriales();
        getInfofuc();
        getUsuarios();
    }, [])

    //usduarios 
    const [getusuarios, setGetusuarios] = useState([]);
    const getUsuarios = async () => {
        let idrol = localStorage.getItem("idrol");
        // debugger
        const res = await fetch(url + "Clogin", {
            method: "POST",
            body: JSON.stringify({ idrol: idrol, function: "getUsuarios" }),
            headers: {
                'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
                'Content-Type': 'application/json',
            },
        })
        const getusuarios = await res.json();
        setGetusuarios(getusuarios.Usuarios);
    }

    const [materiales, setMateriales] = useState([]);
    const getMateriales = async () => {
        // debugger
        const res = await fetch(url + "ProcesoMantenimiento/Cmantenimiento", {
            method: "POST",
            body: JSON.stringify({ fucpadre: localStorage.getItem('fucpadre'), function: "getMateriales" }),
            headers: {
                'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
                'Content-Type': 'application/json',
            },
        })
        const mat = await res.json();
        setMateriales(mat)
    }

    //consultainformacion guardada
    const [infoFuc, setInfoFuc] = useState([]);
    const [maquinas, setMaquinas] = useState([]);
    const getInfofuc = async () => {
        // debugger
        const res = await fetch(url + "ProcesoMantenimiento/Cmantenimiento", {
            method: "POST",
            body: JSON.stringify({ idfuc: localStorage.getItem('idfuc'), function: "getInfoFuc" }),
            headers: {
                'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
                'Content-Type': 'application/json',
            },
        })
        const info = await res.json();
        setInfoFuc(info.infofuc);
        setMaquinas(info.maquinas);
    }

    // actualizar materiales gastados
    const updateGastados = (e, index, id) => {
        let valor = [];
        valor[index] = e.target.value;
        let dat = valor.join();
        fetch(url + "ProcesoMantenimiento/Cmantenimiento",
            {
                method: "POST",
                body: JSON.stringify({ function: "updateGastados", id: id, valor: dat }),
                headers: {
                    'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
                    'Content-Type': 'application/json'
                },
            })
            .then(respuesta => respuesta.json())
            .then((rta) => {
                if (rta == 1) {
                    getMateriales();
                }
            })
            .catch(error => {
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    text: 'Error en servidor ' + error.message,
                    showConfirmButton: true,
                    confirmButtonColor: '#3085d6',
                });
            });
    }
    //info Maquinas
    const [InfoMAquina, setInfoMaquina] = useState({
        nombreequipo: "",
        marca: "",
        modelo: "",
        serial: "",
        voltaje: "",
        frecuencia: "",
        otros: "",
        estadoantes: "",
        trabajorealizado: "",
    });

    const GuadarMaquina = (ev) => {
        setInfoMaquina({
            ...InfoMAquina,
            [ev.target.name]: ev.target.value
        })
    }
    const [ArregloMAquina, setArregloMAquina] = useState([]);
    const [contador, setContador] = useState(1);
    const ArrMaquina = () => {
        const dataMAquina = {
            contador: contador,
            nombreequipo: InfoMAquina.nombreequipo,
            marca: InfoMAquina.marca,
            modelo: InfoMAquina.modelo,
            serial: InfoMAquina.serial,
            voltaje: InfoMAquina.voltaje,
            frecuencia: InfoMAquina.frecuencia,
            otros: InfoMAquina.otros,
            estadoantes: InfoMAquina.estadoantes,
            trabajorealizado: InfoMAquina.trabajorealizado,
        }
        var valid = true;
        var mesaje = "";
        if (dataMAquina.nombreequipo.length <= 0 || dataMAquina.nombreequipo == null) {
            valid = false;
            mesaje = mesaje + "<br> Nombre de equipo es obligatorio."
        }
        if (dataMAquina.marca.length <= 0 || dataMAquina.marca == null) {
            valid = false;
            mesaje = mesaje + "<br> Marca de equipo es obligatorio."
        }
        if (dataMAquina.serial.length <= 0 || dataMAquina.serial == null) {
            valid = false;
            mesaje = mesaje + "<br> Serial de equipo es obligatorio."
        }
        if (dataMAquina.estadoantes.length <= 0 || dataMAquina.estadoantes == null) {
            valid = false;
            mesaje = mesaje + "<br> Describa el estado del equipo antes de la intervención."
        }
        if (dataMAquina.trabajorealizado.length <= 0 || dataMAquina.trabajorealizado == null) {
            valid = false;
            mesaje = mesaje + "<br> Describa el trabajo realizado."
        }
        if (!valid) {
            mesajeerror(mesaje);
        } else {
            setArregloMAquina([...ArregloMAquina, dataMAquina]);
            setContador(contador + 1);
        }
    }

    const deleteD = (id) => {
        setArregloMAquina(ArregloMAquina.filter(contador => contador.contador !== id))
    }

    function mesajeerror(mesaje) {
        MySwal.fire({
            icon: 'info',
            title: '<div style="font-size: 24px">Verificación de datos</div>',
            html: '<div style="color: red; font-size: 18px">Existen datos incompletos:</div> <div style="font-size: 14px">' + mesaje + '</div>',
            confirmButtonColor: 'green',
            footer: '<div style="color: red">Todos los campos son obligatorios.</div>'
        })
    }

    function cancelar() {
        histori.push("/Principal");
    }

    //enviar datos 
    const [data, setData] = useState({
        observaciones: "",
        lugar: ""
    });

    const inputRegistro = (ev) => {
        setData({
            ...data,
            [ev.target.name]: ev.target.value
        })
    }
    const [tecnico2, setTecnico] = useState("0");
    const [jefepista, setJefepista] = useState("0");
    const Tecnico2 = (value) => {
        setTecnico(value.idactor);
    }
    const Jefepista = (value) => {
        setJefepista(value.idactor);
    }
    const enviarDatos = (ev) => {
        ev.preventDefault();
        setCargar(true);
        setValue("Por favor espere...");
        const desarrollofuc = localStorage.getItem("iddesarrollofuc");
        let funct = "";
        if (desarrollofuc == null || desarrollofuc == "") {
            funct = "saveInfoMante";
        } else {
            funct = "UpdateFucMante";
        }
        const datos = {
            function: funct,
            document: localStorage.getItem('document'),
            image: localStorage.getItem('image'),
            arrmaquina: ArregloMAquina,
            observaciones: data.observaciones,
            desarrollofuc: desarrollofuc,
            lugar: data.lugar,
            idtipofuc: localStorage.getItem('idtipofuc'),
            idfuc: localStorage.getItem('idfuc'),
            jefepista: jefepista,
            tecnico2: tecnico2,
        }
        var valid = true;
        var mesaje = "";
        if (desarrollofuc == null || desarrollofuc == "") {
            if (datos.lugar.length <= 0 || datos.lugar == null) {
                valid = false;
                mesaje = mesaje + "<br> Ingrese el lugar del servicio."
            }
        }
        if (!valid) {
            mesajeerror(mesaje);
            setCargar(false);
            setValue("Guardar");
        } else {
            fetch(url + "ProcesoMantenimiento/Cmantenimiento",
                {
                    method: "POST",
                    body: JSON.stringify(datos),
                    headers: {
                        'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
                        'Content-Type': 'application/json'
                    },
                })
                .then(respuesta => respuesta.json())
                .then((rta) => {
                    if (rta == 1) {
                        setCargar(false);
                        setValue("Guardar");
                        MySwal.fire({
                            position: 'center',
                            icon: 'info',
                            text: 'La información se guardo con exito.',
                            showConfirmButton: true,
                            confirmButtonColor: '#3085d6',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                histori.push("/Principal");
                            } else {
                                histori.push("/Principal");
                            }
                        })
                    } else {
                        setCargar(false);
                        setValue("Guardar");
                        MySwal.fire({
                            position: 'center',
                            icon: 'error',
                            text: 'Problemas al guardar información.' + rta,
                            showConfirmButton: true,
                            confirmButtonColor: '#3085d6',
                        });
                    }
                })
                .catch(error => {
                    setCargar(false);
                    setValue("Guardar");
                    MySwal.fire({
                        position: 'center',
                        icon: 'error',
                        text: 'Error en servidor ' + error.message,
                        showConfirmButton: true,
                        confirmButtonColor: '#3085d6',
                    });
                });
        }
    }

    //acordion 
    const [expanded, setExpanded] = useState(false);
    const [acorMaquina, setAcormaquina] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const handleMAquina = (panel) => (event, isExpanded) => {
        setAcormaquina(isExpanded ? panel : false);
    };
    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        Editar Fuc - {consecutivo}
                    </Typography>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Headeditfuc />
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} style={{ boxShadow: "0px 0px 1px  black", marginTop: "24px", width: "100%" }}>
                        <form className={classes.form} autoComplete="off" onSubmit={enviarDatos}>
                            <Grid container spacing={1} style={{ justifyContent: "center", display: "flex" }} >
                                <Grid item xs={12} sm={12} >
                                    <Typography variant="subtitle1" align="center">
                                        <strong style={{ marginLeft: "24px" }}>Datos de la maquina</strong>
                                    </Typography>
                                    <Grid container spacing={1} >
                                        <Grid item xs={12} sm={6} md={4} xl={6} lg={3}>
                                            <Box>
                                                <TextField
                                                    fullWidth
                                                    id="nombreequipo"
                                                    variant="outlined"
                                                    label="Nombre equipo"
                                                    type="text"
                                                    name="nombreequipo"
                                                    onChange={GuadarMaquina}
                                                    value={InfoMAquina.nombreequipo}
                                                    style={{ marginTop: "16px" }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <Box>
                                                <TextField
                                                    fullWidth
                                                    id="marca"
                                                    variant="outlined"
                                                    label="Marca"
                                                    type="text"
                                                    name="marca"
                                                    onChange={GuadarMaquina}
                                                    value={InfoMAquina.marca}
                                                    style={{ marginTop: "16px" }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={3} >
                                            <Box>
                                                <TextField
                                                    fullWidth
                                                    id="modelo"
                                                    variant="outlined"
                                                    label="Modelo"
                                                    type="text"
                                                    name="modelo"
                                                    onChange={GuadarMaquina}
                                                    value={InfoMAquina.modelo}
                                                    style={{ marginTop: "16px" }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <Box>
                                                <TextField
                                                    fullWidth
                                                    id="serial"
                                                    variant="outlined"
                                                    label="Serial"
                                                    type="text"
                                                    name="serial"
                                                    onChange={GuadarMaquina}
                                                    value={InfoMAquina.serial}
                                                    style={{ marginTop: "16px" }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <Box>
                                                <TextField
                                                    fullWidth
                                                    id="voltaje"
                                                    variant="outlined"
                                                    label="Voltaje"
                                                    type="number"
                                                    name="voltaje"
                                                    onChange={GuadarMaquina}
                                                    value={InfoMAquina.voltaje}
                                                    style={{ marginTop: "16px" }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <Box>
                                                <TextField
                                                    fullWidth
                                                    id="frecuencia"
                                                    variant="outlined"
                                                    label="Frecuencia"
                                                    type="number"
                                                    name="frecuencia"
                                                    onChange={GuadarMaquina}
                                                    value={InfoMAquina.frecuencia}
                                                    style={{ marginTop: "16px" }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={6}>
                                            <Box>
                                                <TextField
                                                    fullWidth
                                                    id="otros"
                                                    variant="outlined"
                                                    label="Otros"
                                                    type="text"
                                                    name="otros"
                                                    onChange={GuadarMaquina}
                                                    value={InfoMAquina.otros}
                                                    style={{ marginTop: "16px" }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12}   >
                                            <Typography variant="subtitle1" align="center">
                                                <strong style={{ marginLeft: "24px" }}>Estado antes de la intervención</strong>
                                            </Typography>
                                            <Box >
                                                <TextareaAutosize name="estadoantes" id="estadoantes" onChange={GuadarMaquina} style={{ width: "100%" }} aria-label="minimum height" rowsMin={3} placeholder="Describa el proceso realizado." />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12}   >
                                            <Typography variant="subtitle1" align="center">
                                                <strong style={{ marginLeft: "24px" }}>Trabajo realizado</strong>
                                            </Typography>
                                            <Box >
                                                <TextareaAutosize name="trabajorealizado" id="trabajorealizado" onChange={GuadarMaquina} style={{ width: "100%" }} aria-label="minimum height" rowsMin={3} placeholder="Describa el proceso realizado." />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} style={{ justifyContent: "center", display: "flex", marginTop: "10px" }} >
                                            <Box >
                                                <Button onClick={() => (ArrMaquina())} style={{ backgroundColor: "green", color: "white", textAlign: "center" }}><PlaylistAddIcon style={{ marginRight: "8px" }} />Agregar</Button>
                                            </Box>
                                        </Grid>
                                        {
                                            ArregloMAquina.map(item => (
                                                <Grid item xs={12} sm={6} md={6} lg={6} >
                                                    <Card style={{ boxShadow: "0px 0px 1px  black" }}>
                                                        <CardActionArea>
                                                            <CardContent>
                                                                <Typography gutterBottom style={{ fontSize: "15px" }}>
                                                                    <strong>{item.nombreequipo}</strong>
                                                                </Typography>
                                                                <Typography variant="body2" style={{ color: "black" }} component="p">
                                                                    <strong>Marca: </strong>{item.marca}<br />
                                                                    <strong>Modelo: </strong>{item.modelo}<br />
                                                                    <strong>Serial: </strong>{item.serial}<br />
                                                                    <strong>Voltaje: </strong>{item.voltaje}<br />
                                                                    <strong>Frecuencia: </strong>{item.frecuencia}<br />
                                                                    <strong>Otros: </strong>{item.otros}<br />
                                                                    <strong>Estado antes: </strong>
                                                                    <div>{item.estadoantes}</div><br />
                                                                    <strong>Estado despues: </strong>
                                                                    <div>{item.trabajorealizado}</div><br />
                                                                </Typography>
                                                                <Grid item xs={12} sm={12} style={{ justifyContent: "center", display: "flex" }} >
                                                                    <IconButton aria-label="Eliminar" title="Eliminar" style={{ color: "red" }}>
                                                                        <CloseIcon style={{ height: "35px", width: "42px" }} onClick={(ev) => deleteD(item.contador)} />
                                                                    </IconButton>
                                                                </Grid>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>
                                                </Grid>
                                            ))
                                        }

                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <Accordion expanded={acorMaquina === 'panel1'} onChange={handleMAquina('panel1')}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                    style={{ backgroundColor: "#FCF3CF" }}
                                                >
                                                    <Typography style={{ color: "black" }}>Maquinas guardadas</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                        <Grid container spacing={1}>
                                                            {
                                                                maquinas.map((item, index) => (
                                                                    <Grid item xs={12} sm={6} md={6} lg={4}>
                                                                        <Card style={{ boxShadow: "0px 0px 1px  black" }}>
                                                                            <CardActionArea>
                                                                                <CardContent>
                                                                                    <Typography gutterBottom style={{ fontSize: "15px" }}>
                                                                                        <strong>{item.nombreequipo}</strong>
                                                                                    </Typography>
                                                                                    <Typography variant="body2" color="textSecondary" component="p">
                                                                                        <strong>Marca: </strong>{item.marca}<br />
                                                                                        <strong>Modelo: </strong>{item.modelo} <br />
                                                                                        <strong>Serial: </strong>{item.serial} <br />
                                                                                        <strong>Voltaje: </strong>{item.voltaje} <br />
                                                                                        <strong>Frecuencia: </strong>{item.frecuencia} <br />
                                                                                        <strong>Otros: </strong>{item.otros} <br />
                                                                                        <strong>Estado antes: </strong>{item.estadoantes} <br />
                                                                                        <strong>Trabajo realizado: </strong>{item.trabajorealizado} <br />
                                                                                    </Typography>
                                                                                </CardContent>
                                                                            </CardActionArea>
                                                                        </Card>
                                                                    </Grid>
                                                                ))
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>

                                        <Grid item xs={12} sm={12} style={{ marginTop: "10px" }}>
                                            <Typography variant="subtitle1" align="center" >
                                                <strong >Registro</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item item xs={12} sm={6} md={4} lg={4}  >
                                            <Box >
                                                <Autocomplete
                                                    options={getusuarios}
                                                    getOptionLabel={(option) => option.nombre}
                                                    id="idtec2"
                                                    name="idtec2"
                                                    onChange={(ev, value) => Tecnico2(value)}
                                                    renderInput={(params) => <TextField {...params} label="Tecnico 2" margin="normal" variant="outlined" />}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item item xs={12} sm={6} md={4} lg={4}  >
                                            <Box >
                                                <Autocomplete
                                                    options={getusuarios}
                                                    getOptionLabel={(option) => option.nombre}
                                                    id="idjefepista"
                                                    name="idjefepista"
                                                    onChange={(ev, value) => Jefepista(value)}
                                                    renderInput={(params) => <TextField {...params} label="Jefe pista" margin="normal" variant="outlined" />}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item item xs={12} sm={6} md={4} lg={4} style={{ marginTop: "15px" }}>
                                            <Box >
                                                <FormControl className={classes.formControl} autoComplete="off" fullWidth variant="outlined">
                                                    <InputLabel id="demo-simple-select-outlined-label">Lugar de servicio</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="lugar"
                                                        name="lugar"
                                                        value={data.lugar}
                                                        onChange={inputRegistro}
                                                        label="Lugar de servicio"
                                                    >
                                                        <MenuItem value="">
                                                            <em>Seleccionar</em>
                                                        </MenuItem>
                                                        <MenuItem value={1}>Tecmmas</MenuItem>
                                                        <MenuItem value={2}>Cliente</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Box >
                                                <TextareaAutosize name="observaciones" id="observaciones" onChange={inputRegistro} style={{ width: "100%" }} aria-label="minimum height" rowsMin={3} placeholder="Ingrese las observaciones." />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                    style={{ backgroundColor: "#FCF3CF" }}
                                                >
                                                    <Typography style={{ color: "black" }}>Materiales guardados</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                        <Grid container spacing={1}>
                                                            {
                                                                materiales.map((item, index) => (
                                                                    <Grid item xs={12} sm={6} md={6} lg={4}>
                                                                        <Card style={{ boxShadow: "0px 0px 1px  black" }}>
                                                                            <CardActionArea>
                                                                                <CardContent>
                                                                                    <Typography gutterBottom style={{ fontSize: "15px" }}>
                                                                                        <strong>{item.material}</strong>
                                                                                    </Typography>
                                                                                    <Typography variant="body2" color="textSecondary" component="p">
                                                                                        <strong>Codigo: </strong>{item.codigo}<br />
                                                                                        <strong>Cantidad Solicitada: </strong>{item.cantidad} <br />
                                                                                        <strong>Cantidad Entregada: </strong>{item.cantidadentregada} <br />
                                                                                        <strong>Cantidad Gastada: </strong>{item.cantidadgastada} <br />
                                                                                        <input name={index} id={item.idinfoMateriales} onBlur={(e) => updateGastados(e, index, item.idinfoMateriales)} style={{ width: "50%" }} type="number" placeholder="Ingrese la cantidad" />
                                                                                    </Typography>
                                                                                </CardContent>
                                                                            </CardActionArea>
                                                                        </Card>
                                                                    </Grid>
                                                                ))
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                        <Grid item xs={12} sm={12} style={{ boxShadow: "0px 0px 1px  black" }} >
                                            <Archivos />
                                        </Grid>
                                        {
                                            infoFuc.map(item => (
                                                <Grid item xs={12} sm={12} lg={12} style={{ boxShadow: "0px 0px 1px  black" }} key={Math.random()} >
                                                    <Grid container spacing={1} style={{ marginTop: "8px" }} >
                                                        <Grid item item xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
                                                            <strong style={{ marginRight: "8px" }}> Informacion guardada</strong>
                                                        </Grid>
                                                        <Grid item item xs={12} sm={6} md={4} lg={6}>
                                                            <strong style={{ marginRight: "8px" }}>Lugar de servicio: </strong><br></br>{item.lugar}
                                                        </Grid>
                                                        <Grid item item xs={12} sm={6} md={4} lg={6}>
                                                            <strong style={{ marginRight: "8px" }}>Tecnico 2: </strong><br></br>{item.tecnico2}
                                                        </Grid>
                                                        <Grid item item xs={12} sm={6} md={4} lg={6}>
                                                            <strong style={{ marginRight: "8px" }}>Jefe pista: </strong><br></br>{item.jefepista}
                                                        </Grid>
                                                        <Grid item item xs={12} sm={6} md={4} lg={6}>
                                                            <strong style={{ marginRight: "8px" }}>Observaciones: </strong><br></br>{item.observaciones}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ))
                                        }
                                        <Grid container spacing={2} style={{ marginTop: "8px" }} >
                                            <Grid item xs={6} sm={6}>
                                                <Box>
                                                    <Button fullWidth style={{ backgroundColor: "red", color: "white", textAlign: "center" }} onClick={cancelar}><CloseIcon style={{ marginRight: "8px" }} />Cancelar</Button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6} sm={6}>
                                                <Box>
                                                    <Button fullWidth type="submit" style={{ backgroundColor: "#3386FF", color: "white", textAlign: "center" }}> {cargar ? <CircularProgress style={{ marginRight: "10px", color: "white", }} /> : <SaveIcon style={{ marginRight: "8px" }} />} {value}</Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </main >
        </div >
    );
}

Fuceditmantenimiento.propTypes = {
    window: PropTypes.func,
};

export default Fuceditmantenimiento;
