import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Links from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import InputIcon from '@material-ui/icons/Input';
import { useHistory } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import { url } from "../Componentes/baseurl";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      Todos Los Derechos Reservados <br></br>
      TECMMAS S.A.S {' '}
      {new Date().getFullYear()}
      {'.'}
      <br></br>
      <Links color="inherit" target="_blank" href="https://tecmmas.com/sitio/">
        www.tecmmas.com
      </Links>
      <br></br>
      <label style={{ color: "red" }}>Realese 1.0.1</label>

    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(18),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.info.dark,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function Login() {
  localStorage.clear();
  const classes = useStyles();

  const [data, setData] = useState({
    usuario: "",
    password: ""
  });

  const handleInputChange = (ev) => {
    if (data.usuario.length !== 0) {
      setErrorusuario(false);
      setMensajeusuario("");
    }
    if (data.password.length !== 0) {
      setErrorpassword(false);
      setMensajepassword("");
    }
    setData({
      ...data,
      [ev.target.name]: ev.target.value
    })
    // console.log(ev.target.value)
  }

  let histori = useHistory();
  const enviarDatos = (ev) => {
    ev.preventDefault();
    if (data.usuario.length <= 0) {
      setErrorusuario(true);
      setMensajeusuario("Campo obligatorio");
    }
    if (data.password.length <= 0) {
      setErrorpassword(true);
      setMensajepassword("Campo obligatorio");
    } else
      setCargar(true);
    var datos = {
      username: data.usuario,
      password: data.password,
      function: "login"
    }
    fetch(url + "Clogin",
      {
        method: "POST",
        body: JSON.stringify(datos),
        headers: {
          'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
          'Content-Type': 'application/json'
        },
      })
      .then(respuesta => respuesta.json())
      .then((rta) => {
        if (rta['fechavencimiento']) {
          let name = rta['datos'][0].nombre.split(" ");
          let name2 = "";
          if (name[1] == null || name[1] == "") {
            name2 = "";
          } else {
            name2 = name[1];
          }
          localStorage.setItem('user', name[0] + " " + name2);
          localStorage.setItem('idactor', rta['datos'][0].idactor);
          histori.push('/Newcontra');
        } else {
          if (rta == '0') {
            setCargar(false);
            MySwal.fire({
              position: 'center',
              icon: 'info',
              text: 'Usuario o contraseña incorrectos.',
              showConfirmButton: true,
              confirmButtonColor: '#3085d6',
              // timer: 1500
            })
          } else {
            if (rta[0].activo == 1) {
              let name = rta[0].nombre.split(" ");
              let name2 = "";
              if (name[1] == null || name[1] == "") {
                name2 = "";
              } else {
                name2 = name[1];
              }
              localStorage.setItem('user', name[0] + " " + name2);
              localStorage.setItem('idactor', rta[0].idactor);
              localStorage.setItem('idrol', rta[0].idrol);
              histori.push("/Principal");
            } else {
              setCargar(false);
              MySwal.fire({
                position: 'center',
                icon: 'info',
                text: 'Usuario inactivo, comunícate con el administrador del sistema.',
                showConfirmButton: true,
                confirmButtonColor: '#3085d6',
                // timer: 1500
              })
            }
          }
        }
      })
      .catch(error => {
        console.log(error.message);
        setCargar(false);
        MySwal.fire({
          position: 'center',
          icon: 'error',
          text: 'Error en servidor ' + error.message,
          showConfirmButton: true,
          confirmButtonColor: '#3085d6',
          // timer: 1500
        })
      });

  }


  const MySwal = withReactContent(Swal)
  const [mensajeusuario, setMensajeusuario] = useState("");
  const [errorusuario, setErrorusuario] = useState(false);
  const [mensajepassword, setMensajepassword] = useState("");
  const [errorpassword, setErrorpassword] = useState(false);
  const [cargar, setCargar] = useState(false);

  return (
    <Container component="main" maxWidth="xs" >
      <CssBaseline />
      <div className={classes.paper}>
        <img alt='some value' src={"http://sigc.tecmmas.com/Imagenes/logo.png"} width="70%" height="100px" />
        <form className={classes.form} noValidate autoComplete="off" onSubmit={enviarDatos}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="usuario"
            label="Usuario"
            name="usuario"
            autoFocus
            error={errorusuario}
            helperText={mensajeusuario}
            onChange={handleInputChange}

          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
            error={errorpassword}
            helperText={mensajepassword}
            onChange={handleInputChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {cargar ? <CircularProgress style={{ marginRight: "10px", color: "white" }} /> : <InputIcon style={{ marginRight: "8px" }} />}
            Ingresar
          </Button>
          <Grid container>
            {/* <Grid item xs>
              <Link href="#" variant="body2">
                Olvido su contraseña?
              </Link>
            </Grid> */}
            <Grid item>
              <Link to={"/Registro"} style={{ textDecoration: "none", color: "#3991DA" }}>
                {"Registrate"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={4}>
        <Copyright />
      </Box>
    </Container>

  );
}