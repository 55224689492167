import React, { useState, useEffect } from 'react';
import 'date-fns';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import Menu from "../Componentes/Menu";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, Box } from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FolderIcon from '@material-ui/icons/Folder';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Select from '@material-ui/core/Select'
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import CircularProgress from '@material-ui/core/CircularProgress';
import { url} from "./baseurl";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    // backgroundColor: "whitesmoke"
  },

}));

function Crearfuc(props) {
  let histori = useHistory();
  let user = localStorage.getItem("user");
  if (user == null || user == "") {
    histori.push("/");
  }
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  // carga de menu
  const drawer = (
    <Menu />
  );

  //carga de  usuarios 
  const [getusuarios, setGetusuarios] = useState([]);
  const [cliente, setCliente] = useState([]);
  const [btncamera, setBtncamera] = useState(0);
  useEffect(() => {
    try {
      document.createEvent("TouchEvent");
      setBtncamera(1);
    }
    catch (e) {
      setBtncamera(0);
    }
    getUsuarios();
    getpermiso();
  }, [])

  const getUsuarios = async () => {
    let idrol = localStorage.getItem("idrol");
    // debugger
    const res = await fetch(url + "Clogin", {
      method: "POST",
      body: JSON.stringify({ idrol: idrol, function: "getUsuarios" }),
      headers: {
        'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
        'Content-Type': 'application/json',
      },
    })
    const getusuarios = await res.json();
    setGetusuarios(getusuarios.Usuarios);
    setCliente(getusuarios.Cliente);
    
  }

//fin carga de usuarios

//tipo fuc
const [permiso, setPermiso] = useState([]);
const getpermiso = async () => {
  let idrol = localStorage.getItem("idrol");
  // debugger
  const res = await fetch(url + "Cpermisos", {
    method: "POST",
    body: JSON.stringify({ idrol: idrol, function: "getmenu" }),
    headers: {
      'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
      'Content-Type': 'application/json',
    },
  })
  const getpermiso = await res.json();
  setPermiso(getpermiso)
}

//camara 
const [image, setImage] = useState([]);
const [titleImage, setTitleImage] = useState("");
function getCamera() {
  navigator.camera.getPicture(onSuccess, onFail)
  function onSuccess(imageURI) {
    let fechaHora = new Date();
    let fecha = fechaHora.getDate() + '-' + (fechaHora.getMonth() + 1) + '-' + fechaHora.getFullYear();
    let hora = fechaHora.getHours() + ':' + fechaHora.getMinutes() + ':' + fechaHora.getSeconds();
    var canvas = document.getElementById('cFoto1');
    var context = canvas.getContext('2d');
    var f1 = new Image();
    f1.src = imageURI;
    f1.onload = function () {
      setTitleImage("Imagenes Tomadas");
      context.font = "20px Arial";
      context.drawImage(f1, 0, 0, 640, 480);
      context.fillStyle = "#f5f5f5";
      context.fillRect(10, 8, 270, 33);
      context.strokeStyle = "black";
      context.strokeText("FUC - " + fecha + ' ' + hora, 10, 30);
      // var foto1 = document.getElementById('image1');
      var foto = canvas.toDataURL('image/jpeg', 0.6);//Tamaño
      // foto1.src = foto;
      // foto1.value = '';
      console.log(foto);
      const dataImage = {
        imagen: foto
      }
      setImage([...image, dataImage]);
    }
  }
}

function onFail(message) {
  alert('Failed because: ' + message);
}
//fin camara

//carga de documentos
const [doc, setDoc] = useState([]);
const [file, setFile] = useState([]);
const [titileDcument, setTitileDcument] = useState("");
const fileDocument = (ev) => {
  let val = true;
  let mesaje = "";
  let typeFile = ev[0].type
  let peso = ev[0].size
  if (typeFile !== "application/pdf" && typeFile !== "image/jpeg" && typeFile !== "image/png" && typeFile !== "image/jpg") {
    mesaje = mesaje + "El tipo de archivo que trata de cargar no es valido. <br> Solo se admiten Pdf he Imagenes. <br>";
    val = false;
  }
  if (peso > 1048576) {
    mesaje = mesaje + "El tipo de archivo que trata de cargar excede el peso limite. <br> Maximo tamaño 1048576 Bytes."
    val = false
  }
  if (!val) {
    MySwal.fire({
      icon: 'info',
      html: mesaje,
      confirmButtonColor: 'green',
    })
  } else {
    setTitileDcument("Documentos Cargados");
    Array.from(ev).forEach(ev => {
      var render = new FileReader();
      render.readAsDataURL(ev);
      render.onload = function () {
        // var arrayAuxiliar = [];
        var doc = render.result;
        // arrayAuxiliar = doc.split(',');
        setFile([...file, doc]);
      }
    })
    const dataDocument = {
      name: ev[0].name
    }
    setDoc([...doc, dataDocument]);
  }

}
// fin carga de documentos

// envio y registro de datos
const MySwal = withReactContent(Swal);
const [tipofuc, setTipofuc] = useState("");
const [idinteresado, setIdinteresado] = useState("");
// const [idcreador, setIdcreador] = useState("");
const [idrespondable, setIdrespondable] = useState("");
const [cargar, setCargar] = useState(false);
const [value, setValue] = useState("Guardar");
const [data, setData] = useState({
  descripcion: "",
  estado: "",
  prioridad: "",
  fechafinal: "",
  tipo_servicio: "",
  viaticos: "",
  tipoorden: "",
});

const handleInputChange = (ev) => {
  setData({
    ...data,
    [ev.target.name]: ev.target.value
  })
  // console.log(ev.target.value)
}

const enviarDatos = (ev) => {
  setCargar(true);
  setValue("Por favor espere...");
  ev.preventDefault();
  var datos = {
    descripcion: data.descripcion,
    estado: data.estado,
    prioridad: data.prioridad,
    idinteresado: idinteresado.idactor,
    idcreador: localStorage.getItem("idactor"),
    idresponsable: idrespondable.idactor,
    fechafinal: data.fechafinal,
    imagen: image,
    idtipo: tipofuc.idtipo,
    file: file,
    consecutivo: tipofuc.consecutivo,
    tipo_servicio: data.tipo_servicio,
    viaticos: data.viaticos,
    // tipoorden: data.tipoorden,
    function: "CrearFuc"
  }
  // console.log(datos);

  //validacion de datos
  let validate = true;
  let mesaje = "";
  if (tipofuc.idtipo == 2 || tipofuc.idtipo == 3) {
    if (data.viaticos.length <= 0 || data.viaticos == null) {
      mesaje = mesaje + '<br> ¿Los viaticos se cobran?'
      validate = false;
    }
  }
  if (tipofuc.length <= 0 || tipofuc == null) {
    mesaje = mesaje + '<br> ¿Tipo de fuc?'
    validate = false;
  }
  if (data.tipo_servicio.length <= 0 || data.tipo_servicio == null) {
    mesaje = mesaje + '<br> ¿Tipo de Servicio?'
    validate = false;
  }
  if (idinteresado.length <= 0 || idinteresado == null) {
    mesaje = mesaje + '<br> ¿Usuario o cliente?'
    validate = false;
  }
  // if (idcreador.length <= 0 || idcreador == null) {
  //   mesaje = mesaje + '<br> ¿Usuario que crea Fuc?'
  //   validate = false;
  // }
  if (data.fechafinal.length <= 0 || data.fechafinal == null) {
    mesaje = mesaje + '<br> ¿Fecha de entrega?'
    validate = false;
  }
  if (data.descripcion.length <= 0 || data.descripcion == null) {
    mesaje = mesaje + '<br> ¿Descripción de Fuc?'
    validate = false;
  }
  if (idrespondable.length <= 0 || idrespondable == null) {
    mesaje = mesaje + '<br> ¿Asignación de responsable?'
    validate = false;
  }
  if (data.estado.length <= 0 || data.estado == null) {
    mesaje = mesaje + '<br> ¿Asignación de estado?'
    validate = false;
  }
  if (data.prioridad.length <= 0 || data.prioridad == null) {
    mesaje = mesaje + '<br> ¿Asignación de prioridad?'
    validate = false;
  }
  //fin validacion de datos
  if (!validate) {
    MySwal.fire({
      icon: 'info',
      title: '<div style="font-size: 24px">Verificación de datos</div>',
      html: '<div style="color: red; font-size: 18px">Existen datos incompletos:</div> <div style="font-size: 14px">' + mesaje + '</div>',
      confirmButtonColor: 'green',
      footer: '<div style="color: red">Todos los campos son obligatorios.</div>'
    })
    setCargar(false);
    setValue("Guardar");
  } else {
    setCargar(true);
    setValue("Por favor espere...");
    fetch(url + "Cfuc",
      {
        method: "POST",
        body: JSON.stringify(datos),
        headers: {
          'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
          'Content-Type': 'application/json'
        },
      })
      .then(respuesta => respuesta.json())
      .then((rta) => {
        if (rta == 1) {
          setCargar(false);
          setValue("Guardar");
          ev.target.reset();
          MySwal.fire({
            position: 'center',
            icon: 'info',
            text: 'El Fuc se creo con exito.',
            showConfirmButton: true,
            confirmButtonColor: '#3085d6',
          }).then((result) => {
            if (result.isConfirmed) {
              histori.push("/Principal");
            } else {
              histori.push("/Principal");
            }
          })

        } else {
          setCargar(false);
          setValue("Guardar");
          MySwal.fire({
            position: 'center',
            icon: 'error',
            text: 'Problemas al guardar información.' + rta,
            showConfirmButton: true,
            confirmButtonColor: '#3085d6',
          });

        }
      })
      .catch(error => {
        setCargar(false);
        setValue("Guardar");
        console.log(error.message);
        MySwal.fire({
          position: 'center',
          icon: 'error',
          text: 'Error en servidor ' + error.message,
          showConfirmButton: true,
          confirmButtonColor: '#3085d6',
        });
      });
  }
}

//fin registro de datos

function cancelar() {
  histori.push("/Principal");
}

return (
  <div className={classes.root}>
    <CssBaseline />
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap>
          Crear Fuc
        </Typography>
      </Toolbar>
    </AppBar>
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Grid container spacing={3} >
        <Grid item xs={12} sm={12} style={{ boxShadow: "0px 0px 1px  black" }}>
          <form className={classes.form} autoComplete="off" onSubmit={enviarDatos}>
            <Typography variant="h6" align="center">
              <strong style={{ marginLeft: "24px" }}>Creación y asignación de fuc</strong>
            </Typography>
            <Grid container spacing={1} >
              <Grid item xs={12} sm={6} md={4} lg={3}   >
                <Box >
                  <Autocomplete
                    options={permiso}
                    getOptionLabel={(option) => option.nombret}
                    id="idinteresado"
                    name="idinteresado"
                    onChange={(ev, value) => setTipofuc(value)}
                    renderInput={(params) => <TextField {...params} label="Tipo fuc" margin="normal" variant="outlined" />}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}  >
                <Box >
                  <FormControl className={classes.formControl} autoComplete="off" fullWidth style={{ marginTop: "16px" }} variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">Tipo de servicio</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="tipo_servicio"
                      name="tipo_servicio"
                      value={data.tipo_servicio}
                      onChange={handleInputChange}
                      label="Tipo de servicio"
                    >
                      <MenuItem value="">
                        <em>Seleccionar</em>
                      </MenuItem>
                      <MenuItem value={1}>Instalación</MenuItem>
                      <MenuItem value={2}>Mantenimiento</MenuItem>
                      <MenuItem value={3}>Garantia</MenuItem>
                      <MenuItem value={4}>Reparación</MenuItem>
                      <MenuItem value={5}>Acompañamiento</MenuItem>
                      <MenuItem value={6}>Otros</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}   >
                <Box >
                  <Autocomplete
                    options={cliente}
                    getOptionLabel={(option) => option.nombre}
                    id="idinteresado"
                    name="idinteresado"
                    onChange={(ev, value) => setIdinteresado(value)}
                    renderInput={(params) => <TextField {...params} label="Usuario o Cliente" margin="normal" variant="outlined" />}
                  />
                </Box>
              </Grid>
              {/* <Grid item xs={12} sm={3}   >
                  <Box >
                    <Autocomplete
                      options={getusuarios}
                      getOptionLabel={(option) => option.nombre}
                      id="idcreador"
                      name="idcreador"
                      onChange={(ev, value) => setIdcreador(value)}
                      renderInput={(params) => <TextField {...params} label="Usuario que crea Fuc" margin="normal" variant="outlined" />}
                    />
                  </Box>
                </Grid> */}
              <Grid item xs={12} sm={6} md={4} lg={3}  >
                <Box >
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="date"
                    label="Fecha entrega."
                    type="Date"
                    name="fechafinal"
                    format="yyyy-MM-dd"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleInputChange}
                    value={data.fechafinal}
                    style={{ marginTop: "16px" }}
                  />
                </Box>
              </Grid>
              <Grid item sm={12} xs={12} >
                <Typography variant="body2" color="textSecondary" align="left" style={{ fontSize: "18px" }}>
                  <strong>Descripcion de entradas</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}  >
                <Box>
                  <TextareaAutosize name="descripcion" id="descripcion" onChange={handleInputChange} style={{ width: "100%" }} aria-label="minimum height" rowsMin={10} placeholder="Describa el problema o la necesidad presentada, para la creacion de este Fuc" />
                </Box>
                <ButtonGroup style={{ justifyContent: "center", display: "flex", marginTop: "10px" }} aria-label="contained primary button group">
                  {btncamera == 1 ? <Button onClick={getCamera} style={{ color: "white", backgroundColor: "green" }}><PhotoCamera style={{ marginRight: "8px" }} />FOTO</Button> : ""}
                  <input id="documento" name="documento" type="file" onChange={(e) => fileDocument(e.target.files)} style={{ display: "none" }} />
                  <label htmlFor="documento">
                    <Button component="span" style={{ color: "white", backgroundColor: "#111EE2" }}><FolderIcon style={{ marginRight: "8px" }} />Archivo</Button>
                  </label>
                </ButtonGroup>
              </Grid>
              <Grid item xs={12} sm={6} >
                <Typography variant="body2" color="textSecondary" align="left" style={{ fontSize: "18px" }}>
                  <strong>{titleImage}</strong>
                </Typography>
                {
                  image.map(item => (
                    <img alt='some value' style={{ margin: "5px" }} key={item.imagen} src={item.imagen} width="80px" height="80px" />
                  ))
                }
              </Grid>
              <Grid item xs={12} sm={6}  >
                <Typography variant="body2" color="textSecondary" align="left" style={{ fontSize: "18px" }}>
                  <strong>{titileDcument}</strong>
                </Typography>
                {
                  doc.map(item => (
                    <ul key={item.name}>
                      <li>{item.name}</li>
                    </ul>
                  ))
                }
              </Grid>
              <Grid item item xs={12} sm={6} md={4} lg={4}  >
                <Box >
                  <Autocomplete
                    options={getusuarios }
                    getOptionLabel={(option) => option.idrol !== '17' ? option.nombre : ""}
                    id="idrespondable"
                    name="idrespondable"
                    onChange={(ev, value) => setIdrespondable(value)}
                    renderInput={(params) => <TextField {...params} label="Asignar Fuc (Responsable)" margin="normal" variant="outlined" />}
                  />
                </Box>
              </Grid>
              <Grid item item xs={12} sm={6} md={4} lg={4}  >
                <Box >
                  <FormControl className={classes.formControl} autoComplete="off" fullWidth style={{ marginTop: "16px" }} variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">Estado</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="estado"
                      name="estado"
                      value={data.estado}
                      onChange={handleInputChange}
                      label="Estado"
                    >
                      <MenuItem value="">
                        <em>Seleccionar</em>
                      </MenuItem>
                      <MenuItem value={1}>Activo</MenuItem>
                      <MenuItem value={2}>Cerrado</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item item xs={12} sm={6} md={4} lg={4}  >
                <Box >
                  <FormControl className={classes.formControl} autoComplete="off" fullWidth style={{ marginTop: "16px" }} variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">Prioridad</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="prioridad"
                      name="prioridad"
                      value={data.prioridad}
                      onChange={handleInputChange}
                      label="Prioridad"
                    >
                      <MenuItem value="">
                        <em>Seleccionar</em>
                      </MenuItem>
                      <MenuItem value={1}>Alta</MenuItem>
                      <MenuItem value={2}>Media</MenuItem>
                      <MenuItem value={3}>Baja</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              {
                tipofuc.idtipo == 2 || tipofuc.idtipo == 3 ?
                  <Grid item xs={12} sm={12} style={{ boxShadow: "0px 0px 1px  black", marginTop: "24px", width: "100%" }}>
                    <Grid container spacing={1} style={{ justifyContent: "center", display: "flex" }} >
                      <Grid item xs={12} sm={2} >
                        <FormControl className={classes.formControl} autoComplete="off" fullWidth style={{ marginTop: "16px" }} variant="outlined">
                          <InputLabel id="demo-simple-select-outlined-label">¿Se cobran viaticos?</InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="viaticos"
                            name="viaticos"
                            value={data.viaticos}
                            onChange={handleInputChange}
                            label="¿Se cobran viaticos?"
                          >
                            <MenuItem value="">
                              <em>Seleccionar</em>
                            </MenuItem>
                            <MenuItem value={1}>Si</MenuItem>
                            <MenuItem value={2}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* <Grid item xs={12} sm={2} >
                          <FormControl className={classes.formControl} autoComplete="off" fullWidth style={{ marginTop: "16px" }} variant="outlined">
                            <InputLabel id="demo-simple-select-outlined-label">Tipo de orden</InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="tipoorden"
                              name="tipoorden"
                              value={data.tipoorden}
                              onChange={handleInputChange}
                              label="Tipo de orden"
                            >
                              <MenuItem value="">
                                <em>Seleccionar</em>
                              </MenuItem>
                              <MenuItem value={"OS"}>OS</MenuItem>
                              <MenuItem value={"OP"}>OP</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid> */}
                    </Grid>
                  </Grid>
                  : ""
              }
              <Grid item xs={6} sm={6}  >
                <Box>
                  <Button fullWidth style={{ backgroundColor: "red", color: "white", textAlign: "center" }} onClick={cancelar}><CancelIcon style={{ marginRight: "8px" }} />Cancelar</Button>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6}  >
                <Box>
                  <Button fullWidth type="submit" style={{ backgroundColor: "#3386FF", color: "white", textAlign: "center" }}> {cargar ? <CircularProgress style={{ marginRight: "10px", color: "white", }} /> : <SaveIcon style={{ marginRight: "8px" }} />} {value}</Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </main>
  </div >
);
}

Crearfuc.propTypes = {
  window: PropTypes.func,
};

export default Crearfuc;