import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ButtonGroup from '@material-ui/core/ButtonGroup';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Button from '@material-ui/core/Button';
import FolderIcon from '@material-ui/icons/Folder';
import Typography from '@material-ui/core/Typography';

export default function Archivos() {
    const MySwal = withReactContent(Swal);
    const [btncamera, setBtncamera] = useState(0);
    useEffect(() => {
        try {
            document.createEvent("TouchEvent");
            setBtncamera(1);
        }
        catch (e) {
            setBtncamera(0);
        }
    }, [])
    // camara
    const [image, setImage] = useState([]);
    localStorage.setItem('image', JSON.stringify(image))
    const [titleImage, setTitleImage] = useState("");
    function getCamera() {
        navigator.camera.getPicture(onSuccess, onFail)
        function onSuccess(imageURI) {
            let fechaHora = new Date();
            let mont = "";
            let dai = "";
            if ((fechaHora.getMonth() + 1) < 10) {
                mont = "0" + (fechaHora.getMonth() + 1);
            } else {
                mont = (fechaHora.getMonth() + 1);
            }
            if (fechaHora.getDate() < 10) {
                dai = "0" + fechaHora.getDate();
            } else {
                dai = fechaHora.getDate();
            }
            let fecha = fechaHora.getFullYear() + '-' + mont + '-' + dai
            let hora = fechaHora.getHours() + ':' + fechaHora.getMinutes() + ':' + fechaHora.getSeconds();
            var canvas = document.getElementById('cFoto1');
            var context = canvas.getContext('2d');
            var f1 = new Image();
            f1.src = imageURI;
            f1.onload = function () {
                setTitleImage("Imagenes Tomadas");
                context.font = "20px Arial";
                context.drawImage(f1, 0, 0, 640, 480);
                context.fillStyle = "#f5f5f5";
                context.fillRect(10, 8, 270, 33);
                context.strokeStyle = "black";
                context.strokeText("FUC - " + fecha + ' ' + hora, 10, 30);
                // var foto1 = document.getElementById('image1');
                var foto = canvas.toDataURL('image/jpeg', 0.6);//Tamaño
                // foto1.src = foto;
                // foto1.value = '';
                console.log(foto);
                const dataImage = {
                    imagen: foto
                }
                setImage([...image, dataImage]);
            }
        }
    }

    function onFail(message) {
        alert('Failed because: ' + message);
    }
    //documentos

    const [doc, setDoc] = useState([]);

    const [file, setFile] = useState([]);
    localStorage.setItem('document', JSON.stringify(file))
    const [titileDcument, setTitileDcument] = useState("");
    const fileDocument = (ev) => {
        let val = true;
        let mesaje = "";
        let typeFile = ev[0].type
        let peso = ev[0].size
        if (typeFile !== "application/pdf" && typeFile !== "image/jpeg" && typeFile !== "image/png" && typeFile !== "image/jpg") {
            mesaje = mesaje + "El tipo de archivo que trata de cargar no es valido. <br> Solo se admiten Pdf he Imagenes. <br>";
            val = false;
        }
        if (peso > 1048576) {
            mesaje = mesaje + "El tipo de archivo que trata de cargar excede el peso limite. <br> Maximo tamaño 1048576 Bytes."
            val = false
        }
        if (!val) {
            MySwal.fire({
                icon: 'info',
                html: mesaje,
                confirmButtonColor: 'green',
            })
        } else {
            setTitileDcument("Documentos Cargados");
            Array.from(ev).forEach(ev => {
                var render = new FileReader();
                render.readAsDataURL(ev);
                render.onload = function () {
                    // var arrayAuxiliar = [];
                    var doc = render.result;
                    // arrayAuxiliar = doc.split(',');
                    setFile([...file, doc]);
                }
            })
            const dataDocument = {
                name: ev[0].name
            }
            setDoc([...doc, dataDocument]);
        }
    }

    return (
        <>
            <Typography variant="subtitle1" align="center" >
                <strong >Carga de archivos</strong>
            </Typography>
            <ButtonGroup style={{ justifyContent: "center", display: "flex", marginTop: "15px" }} aria-label="contained primary button group">
                {btncamera == 1 ? <Button onClick={getCamera} style={{ color: "white", backgroundColor: "green" }}><PhotoCamera style={{ marginRight: "8px" }} />FOTO</Button> : ""}
                <input id="documento" name="documento" type="file" onChange={(e) => fileDocument(e.target.files)} style={{ display: "none" }} />
                <label htmlFor="documento">
                    <Button component="span" style={{ color: "white", backgroundColor: "#111EE2" }}><FolderIcon style={{ marginRight: "8px" }} />Archivo</Button>
                </label>
            </ButtonGroup>
            <Grid item xs={12} sm={6} >
                <Typography variant="body2" color="textSecondary" align="left" style={{ fontSize: "18px" }}>
                    <strong>{titleImage}</strong>
                </Typography>
                {
                    image.map(item => (
                        <img alt='some value' style={{ margin: "5px" }} key={item.imagen} src={item.imagen} width="80px" height="80px" />
                    ))
                }
            </Grid>
            <Grid item xs={12} sm={6} style={{ marginLeft: "8px" }}  >
                <Typography variant="body2" color="textSecondary" align="left" style={{ fontSize: "18px" }}>
                    <strong>{titileDcument}</strong>
                </Typography>
                {
                    doc.map(item => (
                        <ul key={item.name}>
                            <li>{item.name}</li>
                        </ul>
                    ))
                }
            </Grid>
        </>
    );
}
