import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import PostAddOutlinedIcon from '@material-ui/icons/PostAddOutlined';
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { url } from "./baseurl";
import PinDropIcon from '@material-ui/icons/PinDrop';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(2),
    },
}));





export default function Menu() {
    let histori = useHistory();
    let user = localStorage.getItem("user");
    function cerrassesion() {
        localStorage.clear();
        histori.push("/");
    }

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const [menu, setMenu] = useState([]);

    useEffect(() => {
        getmenu();
    }, [])

    const getmenu = async () => {
        let idrol = localStorage.getItem("idrol");
        // debugger
        const res = await fetch(url + "Cpermisos", {
            method: "POST",
            body: JSON.stringify({ idrol: idrol, function: "menu" }),
            headers: {
                'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
                'Content-Type': 'application/json',
            },
        })
        const getmenu = await res.json();
        setMenu(getmenu)
    }


    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader id="nested-list-subheader" style={{ textAlign: "center", fontSize: "18px", color: "white", backgroundColor: "green", height: "64px" }}>
                    {user}
                </ListSubheader>
            }

            className={classes.root}
        >
            <ListItem button onClick={handleClick}>
                <ListItemIcon>
                    <AssignmentOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Fucs" />
                {open ? <ExpandMore /> : <ExpandLess />}
            </ListItem>
            {
                menu.map(item => (
                    item.nombre !== "Geolocalizacion" ?
                        <Collapse in={open} timeout="auto" unmountOnExit key={item.idmenu}>
                            <List component="div" disablePadding>
                                <ListItem button className={classes.nested} component={Link} to={item.to}>
                                    <ListItemIcon>
                                        <PostAddOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={item.nombre} />
                                </ListItem>
                            </List>
                        </Collapse>
                        : ""
                ))
            }
            {
                menu.map(item => (

                    item.nombre == "Geolocalizacion" ?
                        <ListItem button component={Link} to={item.to}>
                            <ListItemIcon>
                                <PinDropIcon />
                            </ListItemIcon>
                            <ListItemText primary={item.nombre} />
                        </ListItem>
                        : ""

                ))
            }
            <ListItem button onClick={cerrassesion}>
                <ListItemIcon>
                    <PowerSettingsNewIcon />
                </ListItemIcon>
                <ListItemText primary="Cerrar Sesion" />
            </ListItem>

        </List>
    );
}
