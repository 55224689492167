import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { Grid, Box } from '@material-ui/core';
import Menu from "../Componentes/Menu";
import Headeditfuc from "../Componentes/Headeditfuc";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { url } from "../Componentes/baseurl";
import Button from '@material-ui/core/Button';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Archivos from '../Componentes/Archivos';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select'
import MenuItem from "@material-ui/core/MenuItem";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';



const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
}));

function Fucalmacen(props) {
    let histori = useHistory();
    let user = localStorage.getItem("user");
    let consecutivo = localStorage.getItem("consecutivo");
    if (user == null || user == "") {
        histori.push("/");
    }
    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <Menu />
    );


    const container = window !== undefined ? () => window().document.body : undefined;

    const MySwal = withReactContent(Swal);
    // carga de materiales

    useEffect(() => {
        try {
            document.createEvent("TouchEvent");
        }
        catch (e) {
        }
        getMateriales();
        getUsuarios();
        getInfofuc();
    }, [])
    const [materiales, setMateriales] = useState([]);
    const getMateriales = async () => {
        let idrol = localStorage.getItem("idrol");
        // debugger
        const res = await fetch(url + "ProcesoAlmacen/Calmacen", {
            method: "POST",
            body: JSON.stringify({ idrol: idrol, function: "getMateriales" }),
            headers: {
                'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
                'Content-Type': 'application/json',
            },
        })
        const mat = await res.json();
        setMateriales(mat)
    }
    const [getusuarios, setGetusuarios] = useState([]);
    const getUsuarios = async () => {
        let idrol = localStorage.getItem("idrol");
        // debugger
        const res = await fetch(url + "Clogin", {
            method: "POST",
            body: JSON.stringify({ idrol: idrol, function: "getUsuarios" }),
            headers: {
                'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
                'Content-Type': 'application/json',
            },
        })
        const getusuarios = await res.json();
        setGetusuarios(getusuarios.Usuarios)
    }
    const [infoFuc, setInfoFuc] = useState([]);
    const [infoMateriales, setInfoMateriales] = useState([]);
    const getInfofuc = async () => {
        // debugger
        const res = await fetch(url + "ProcesoAlmacen/Calmacen", {
            method: "POST",
            body: JSON.stringify({ idfuc: localStorage.getItem('idfuc'), function: "getInfofuc" }),
            headers: {
                'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
                'Content-Type': 'application/json',
            },
        })
        const info = await res.json();
        setInfoFuc(info.infofuc);
        setInfoMateriales(info.materiales);
    }

    const [Material, setMaterial] = useState("");
    const [contador, setContador] = useState(1);
    //informacion de maquina
    const [mat, setMat] = useState({
        cantidad: ""
    });

    const inpuMaterial = (ev) => {
        setMat({
            ...mat,
            [ev.target.name]: ev.target.value
        })
    }

    const [arrMaterial, setArrMaterial] = useState([])
    const SaveData = () => {
        const dataMaterial = {
            contador: contador,
            cantidad: mat.cantidad,
            material: Material.descripcion,
            codigo: Material.codigo,
            idmateriales: Material.idmateriales
        }
        var valid = true;
        var mesaje = "";
        if (dataMaterial.cantidad.length <= 0 || dataMaterial.cantidad == null) {
            valid = false;
            mesaje = mesaje + "<br> Ingrese la cantidad solicitada."
        }
        if (Material.length <= 0 || Material == null) {
            valid = false;
            mesaje = mesaje + "<br> Ingrese el material."
        }
        if (!valid) {
            mesajeerror(mesaje);
        } else {
            setArrMaterial([...arrMaterial, dataMaterial]);
            setContador(contador + 1);
        }
    }

    const deleteD = (id) => {
        setArrMaterial(arrMaterial.filter(contador => contador.contador !== id))
    }

    function mesajeerror(mesaje) {
        MySwal.fire({
            icon: 'info',
            title: '<div style="font-size: 24px">Verificación de datos</div>',
            html: '<div style="color: red; font-size: 18px">Existen datos incompletos:</div> <div style="font-size: 14px">' + mesaje + '</div>',
            confirmButtonColor: 'green',
            footer: '<div style="color: red">Todos los campos son obligatorios.</div>'
        })
    }

    //function cancelar
    function cancelar() {
        histori.push("/Principal");
    }

    //guardar informacion 
    const [cargar, setCargar] = useState(false);
    const [value, setValue] = useState("Guardar");
    const [entregador, setEntregador] = useState("0");
    const [alistador, setAlistador] = useState("0");
    const [receptor, setReceptor] = useState("0");
    const [devolucionmaterial, setDevolucionmaterial] = useState("0");
    const [receptormaterial, setReceptormaterial] = useState("0");
    const Entregador = (value) => {
        setEntregador(value.idactor);
    }
    const Alistador = (value) => {
        setAlistador(value.idactor)
    }
    const Receptor = (value) => {
        setReceptor(value.idactor)
    }
    const Devolucionmaterial = (value) => {
        setDevolucionmaterial(value.idactor)
    }
    const Receptormaterial = (value) => {
        setReceptormaterial(value.idactor)
    }

    const [data, setData] = useState({
        observaciones: "",
        tiposervicio: ""
    });

    const inputRegistro = (ev) => {
        setData({
            ...data,
            [ev.target.name]: ev.target.value
        })
    }

    const enviarDatos = (ev) => {
        ev.preventDefault();
        setCargar(true);
        setValue("Por favor espere...");
        const desarrollofuc = localStorage.getItem("iddesarrollofuc");
        let fucntion = "";
        if (desarrollofuc == null || desarrollofuc == "") {
            fucntion = "saveInfo";
        } else {
            fucntion = "UpdateFucAlmacen"
        }
        const datos = {
            function: fucntion,
            observaciones: data.observaciones,
            arrMaterial: arrMaterial,
            entregador: entregador,
            alistador: alistador,
            receptor: receptor,
            idtipofuc: localStorage.getItem('idtipofuc'),
            idfuc: localStorage.getItem('idfuc'),
            devolucionmaterial: devolucionmaterial,
            receptormaterial: receptormaterial,
            desarrollofuc: desarrollofuc,
            tiposervicio: data.tiposervicio,
            document: localStorage.getItem('document'),
            image: localStorage.getItem('image'),
        }
        var valid = true;
        var mesaje = "";
        if (desarrollofuc == null || desarrollofuc == "") {
            if (arrMaterial.length <= 0 || arrMaterial == null) {
                valid = false;
                mesaje = mesaje + "<br> Debe ingresar materiales."
            }
            if (data.tiposervicio.length <= 0 || data.tiposervicio == null) {
                valid = false;
                mesaje = mesaje + "<br> Tipo de orden obligatorio."
            }
        }
        if (!valid) {
            mesajeerror(mesaje);
            setCargar(false);
            setValue("Guardar");
        } else {
            fetch(url + "ProcesoAlmacen/Calmacen",
                {
                    method: "POST",
                    body: JSON.stringify(datos),
                    headers: {
                        'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
                        'Content-Type': 'application/json'
                    },
                })
                .then(respuesta => respuesta.json())
                .then((rta) => {
                    if (rta == 1) {
                        setCargar(false);
                        setValue("Guardar");
                        MySwal.fire({
                            position: 'center',
                            icon: 'info',
                            text: 'La información se guardo con exito.',
                            showConfirmButton: true,
                            confirmButtonColor: '#3085d6',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                histori.push("/Principal");
                            } else {
                                histori.push("/Principal");
                            }
                        })
                    } else {
                        setCargar(false);
                        setValue("Guardar");
                        MySwal.fire({
                            position: 'center',
                            icon: 'error',
                            text: 'Problemas al guardar información.' + rta,
                            showConfirmButton: true,
                            confirmButtonColor: '#3085d6',
                        });
                    }
                })
                .catch(error => {
                    setCargar(false);
                    setValue("Guardar");
                    MySwal.fire({
                        position: 'center',
                        icon: 'error',
                        text: 'Error en servidor ' + error.message,
                        showConfirmButton: true,
                        confirmButtonColor: '#3085d6',
                    });
                });
        }
    }

    //actualizar cantidad
    const updateCAntidad = (e, index, id) => {
        let idrol = localStorage.getItem('idrol');
        if (idrol == '13') {
            let valor = [];
            valor[index] = e.target.value;
            let dat = valor.join();
            fetch(url + "ProcesoAlmacen/Calmacen",
                {
                    method: "POST",
                    body: JSON.stringify({ function: "updateCAntidad", id: id, valor: dat }),
                    headers: {
                        'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
                        'Content-Type': 'application/json'
                    },
                })
                .then(respuesta => respuesta.json())
                .then((rta) => {
                    if (rta == 1) {
                        getMateriales();
                        getInfofuc();
                    }
                })
                .catch(error => {
                    setCargar(false);
                    setValue("Guardar");
                    MySwal.fire({
                        position: 'center',
                        icon: 'error',
                        text: 'Error en servidor ' + error.message,
                        showConfirmButton: true,
                        confirmButtonColor: '#3085d6',
                    });
                });
        } else {
            MySwal.fire({
                position: 'center',
                icon: 'error',
                html: '<div font-size: 15px">Usted no tiene permisos para realizar esta operación, solo el encargado de almacén puede actualizar la cantidad entregada.</div>',
                showConfirmButton: true,
                confirmButtonColor: '#3085d6',
            });
        }
    };
    //acordion
    const [acorMateriales, setAcormateriales] = useState(false);

    const handleMateriales = (panel) => (event, isExpanded) => {
        setAcormateriales(isExpanded ? panel : false);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        Editar Fuc - {consecutivo}
                    </Typography>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Headeditfuc />
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} style={{ boxShadow: "0px 0px 1px  black", marginTop: "24px", width: "100%" }}>
                        <form className={classes.form} autoComplete="off" onSubmit={enviarDatos}>
                            <Grid container spacing={1}  >
                                <Grid item xs={12} sm={12} >
                                    <Typography variant="subtitle1" align="center" >
                                        <strong >Registro materiales</strong>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} >
                                    <Box >
                                        <Autocomplete
                                            options={materiales}
                                            getOptionLabel={(option) => option.descripcion}
                                            id="materiales"
                                            name="materiales"
                                            onChange={(ev, value) => setMaterial(value)}
                                            renderInput={(params) => <TextField {...params} label="Materiales" margin="normal" variant="outlined" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} >
                                    <Box>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            onChange={inpuMaterial}
                                            value={mat.cantidad}
                                            id="cantidad"
                                            label="Cantidad"
                                            name="cantidad"
                                            type="number"
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={12} style={{ justifyContent: "center", display: "flex", marginTop: "10px" }} >
                                    <Box >
                                        <Button onClick={() => (SaveData())} style={{ backgroundColor: "green", color: "white", textAlign: "center" }}><PlaylistAddIcon style={{ marginRight: "8px" }} />Agregar</Button>
                                    </Box>
                                </Grid>
                                {
                                    arrMaterial.map(item => (
                                        <Card style={{ boxShadow: "0px 0px 1px  black" }}>
                                            <CardActionArea>
                                                <CardContent>
                                                    <Typography gutterBottom style={{ fontSize: "15px" }}>
                                                        <strong>{item.material}</strong>
                                                    </Typography>
                                                    <Typography variant="body2" style={{ color: "black" }} component="p">
                                                        <strong>Codigo: </strong>{item.codigo}<br />
                                                        <strong>Cantidad solicitada: </strong>{item.cantidad} <br />
                                                    </Typography>
                                                    <Grid item xs={12} sm={12} style={{ justifyContent: "center", display: "flex" }} >
                                                        <IconButton aria-label="Eliminar" title="Eliminar" style={{ color: "red" }}>
                                                            <CloseIcon style={{ height: "35px", width: "42px" }} onClick={(ev) => deleteD(item.contador)} />
                                                        </IconButton>
                                                    </Grid>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    ))
                                }
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Accordion expanded={acorMateriales === 'panel1'} onChange={handleMateriales('panel1')}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            style={{ backgroundColor: "#FCF3CF" }}
                                        >
                                            <Typography style={{ color: "black" }}>Materiales guardados</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Grid container spacing={1}>
                                                    {
                                                        infoMateriales.map((item, index) => (
                                                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                                                <Card style={{ boxShadow: "0px 0px 1px  black" }}>
                                                                    <CardActionArea>
                                                                        <CardContent>
                                                                            <Typography gutterBottom style={{ fontSize: "15px" }}>
                                                                                <strong>{item.material}</strong>
                                                                            </Typography>
                                                                            <Typography variant="body2" color="textSecondary" component="p">
                                                                                <strong>Codigo: </strong>{item.codigo}<br />
                                                                                <strong>Cantidad solicitada: </strong>{item.cantidad} <br />
                                                                                <strong>Cantidad entregada: </strong>{item.cantidadentregada} <br />
                                                                                <input name={index} id={item.idinfoMateriales} onBlur={(e) => updateCAntidad(e, index, item.idinfoMateriales)} style={{ width: "50%" }} type="number" placeholder="Ingrese la cantidad" />
                                                                            </Typography>
                                                                        </CardContent>
                                                                    </CardActionArea>
                                                                    {/* <CardActions>
                                                                                <Button size="small" color="primary">
                                                                                    Share
                                                                                </Button>
                                                                            </CardActions> */}
                                                                </Card>
                                                            </Grid>
                                                        ))
                                                    }
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} style={{ marginTop: "10px" }} >
                                    <Typography variant="subtitle1" align="center" >
                                        <strong >Entrega material</strong>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box >
                                        <FormControl className={classes.formControl} autoComplete="off" fullWidth style={{ marginTop: "16px" }} variant="outlined">
                                            <InputLabel id="demo-simple-select-outlined-label">Tipo de orden</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="tiposervicio"
                                                name="tiposervicio"
                                                value={data.tiposervicio}
                                                onChange={inputRegistro}
                                                label="Tipo de orden"
                                            >
                                                <MenuItem value="">
                                                    <em>Seleccionar</em>
                                                </MenuItem>
                                                <MenuItem value={1}>Producción</MenuItem>
                                                <MenuItem value={2}>Servicio</MenuItem>
                                                <MenuItem value={3}>Prototipo</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box >
                                        <Autocomplete
                                            options={getusuarios}
                                            getOptionLabel={(option) => option.nombre}
                                            id="responsable"
                                            name="responsable"
                                            onChange={(ev, value) => Alistador(value)}
                                            renderInput={(params) => <TextField {...params} label="Persona alista" margin="normal" variant="outlined" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box >
                                        <Autocomplete
                                            options={getusuarios}
                                            getOptionLabel={(option) => option.nombre}
                                            id="responsable"
                                            name="responsable"
                                            onChange={(ev, value) => Entregador(value)}
                                            renderInput={(params) => <TextField {...params} label="Persona entrega" margin="normal" variant="outlined" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box >
                                        <Autocomplete
                                            options={getusuarios}
                                            getOptionLabel={(option) => option.nombre}
                                            id="responsable"
                                            name="responsable"
                                            onChange={(ev, value) => Receptor(value)}
                                            renderInput={(params) => <TextField {...params} label="Receptor" margin="normal" variant="outlined" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} >
                                    <Typography variant="subtitle1" align="center" >
                                        <strong >Devolución de material</strong>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} >
                                    <Box >
                                        <Autocomplete
                                            options={getusuarios}
                                            getOptionLabel={(option) => option.nombre}
                                            id="responsable"
                                            name="responsable"
                                            onChange={(ev, value) => Devolucionmaterial(value)}
                                            renderInput={(params) => <TextField {...params} label="Devolucion Material" margin="normal" variant="outlined" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Box >
                                        <Autocomplete
                                            options={getusuarios}
                                            getOptionLabel={(option) => option.nombre}
                                            id="responsable"
                                            name="responsable"
                                            onChange={(ev, value) => Receptormaterial(value)}
                                            renderInput={(params) => <TextField {...params} label="Receptor Material" margin="normal" variant="outlined" />}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} >
                                <Typography variant="subtitle1" align="center" >
                                    <strong >Observaciones</strong>
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={12} style={{ marginTop: "10px" }}>
                                <Box >
                                    <TextareaAutosize name="observaciones" id="observaciones" onChange={inputRegistro} style={{ width: "100%" }} aria-label="minimum height" rowsMin={3} placeholder="Ingrese las observaciones." />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} style={{ boxShadow: "0px 0px 1px  black" }} >
                                <Archivos />
                            </Grid>
                            {
                                infoFuc.map(item => (
                                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ boxShadow: "0px 0px 1px  black" }} key={Math.random()} >
                                        <Grid container spacing={1} style={{ marginTop: "8px" }} >
                                            <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
                                                <strong style={{ marginRight: "8px" }}> Informacion guardada</strong>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={6}>
                                                <strong style={{ marginRight: "8px" }}>Alistador: </strong><br></br>{item.alistador}
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={6}>
                                                <strong style={{ marginRight: "8px" }}>Entregador: </strong><br></br>{item.entregador}
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={6}>
                                                <strong style={{ marginRight: "8px" }}>Receptor: </strong><br></br>{item.receptor}
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={6}>
                                                <strong style={{ marginRight: "8px" }}>Devolucion material: </strong><br></br>{item.persona_devolucion_material}
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={6}>
                                                <strong style={{ marginRight: "8px" }}>Receptor devolucion: </strong><br></br>{item.persona_recibe_devolucion}
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={6}>
                                                <strong style={{ marginRight: "8px" }}>Observaciones: </strong><br></br>{item.observaciones}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))
                            }

                            <Grid container spacing={2} style={{ marginTop: "8px" }} >
                                <Grid item xs={6} sm={6}>
                                    <Box>
                                        <Button fullWidth style={{ backgroundColor: "red", color: "white", textAlign: "center" }} onClick={cancelar}><CloseIcon style={{ marginRight: "8px" }} />Cancelar</Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Box>
                                        <Button fullWidth type="submit" style={{ backgroundColor: "#3386FF", color: "white", textAlign: "center" }}> {cargar ? <CircularProgress style={{ marginRight: "10px", color: "white", }} /> : <SaveIcon style={{ marginRight: "8px" }} />} {value}</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </main >
        </div >
    );
}

Fucalmacen.propTypes = {
    window: PropTypes.func,
};

export default Fucalmacen;
