import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Toolbar, Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import Menu from "../Componentes/Menu";
import { Grid, Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import 'reactjs-popup/dist/index.css';
import Map from "../Componentes/Map";
import { url } from "../Componentes/baseurl";
import Autocomplete from '@material-ui/lab/Autocomplete';
import SendIcon from '@material-ui/icons/Send';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PinDropIcon from '@material-ui/icons/PinDrop';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import CloseIcon from '@material-ui/icons/Close';
import Popup from 'reactjs-popup';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));


function Geolocalizacion(props) {
    let histori = useHistory();
    let user = localStorage.getItem("user");
    if (user == null || user == "") {
        histori.push("/");
    }
    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <Menu />
    );
    const MySwal = withReactContent(Swal);
    const container = window !== undefined ? () => window().document.body : undefined;
    useEffect(() => {
        getUsuarios();
    }, [])


    //carga de usuarios 
    const [getusuarios, setGetusuarios] = useState([]);
    const getUsuarios = async () => {
        let idrol = localStorage.getItem("idrol");
        // debugger
        const res = await fetch(url + "Clogin", {
            method: "POST",
            body: JSON.stringify({ idrol: idrol, function: "getUsuarios" }),
            headers: {
                'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
                'Content-Type': 'application/json',
            },
        })
        const getusuarios = await res.json();
        setGetusuarios(getusuarios.Usuarios);
    }

    //consulta
    const [perso, setPerso] = useState(0);
    const Personal = (value) => {
        setPerso(value.idactor);
    }
    const [datos, setDatos] = useState({
        fechainicio: "",
        fechafinal: ""
    });
    const Data = (ev) => {
        setDatos({
            ...datos,
            [ev.target.name]: ev.target.value
        })
    }
    const [getlocalizacion, setGetlocalizacion] = useState([]);
    const enviarDatos = (ev) => {
        ev.preventDefault();
        const dat = {
            fechainicio: datos.fechainicio,
            fechafinal: datos.fechafinal,
            idactor: perso,
            function: 'geolocalizacion'
        }
        var valid = true;
        var mesaje = "";
        if ((dat.fechainicio.length <= 0 || dat.fechainicio == null)) {
            valid = false;
            mesaje = mesaje + "<br> Fecha inicial obligatoria."
        }
        if ((dat.fechafinal.length <= 0 || dat.fechafinal == null)) {
            valid = false;
            mesaje = mesaje + "<br> Fecha final obligatoria"
        }
        if ((dat.idactor.length <= 0 || dat.idactor == '0')) {
            valid = false;
            mesaje = mesaje + "<br> Seleccione el usuario."
        }
        if (!valid) {
            mesajeerror(mesaje);
        } else {
            fetch(url + "Formatos/FormatoHoras/Choras",
                {
                    method: "POST",
                    body: JSON.stringify(dat),
                    headers: {
                        'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
                        'Content-Type': 'application/json'
                    },
                })
                .then(respuesta => respuesta.json())
                .then((rta) => {
                    setGetlocalizacion(rta);
                }).catch(error => {
                    MySwal.fire({
                        position: 'center',
                        icon: 'error',
                        text: 'Error en servidor ' + error.message,
                        showConfirmButton: true,
                        confirmButtonColor: '#3085d6',
                    });
                });
        }

    }

    function mesajeerror(mesaje) {
        MySwal.fire({
            icon: 'info',
            title: '<div style="font-size: 24px">Verificación de datos</div>',
            html: '<div style="color: red; font-size: 18px">Existen datos incompletos:</div> <div style="font-size: 14px">' + mesaje + '</div>',
            confirmButtonColor: 'green',
            footer: '<div style="color: red">Todos los campos son obligatorios.</div>'
        })
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        Maps
                    </Typography>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Grid container spacing={1} >
                    <Button variant="outlined" color="primary" style={{ backgroundColor: "#7296F6", color: "whitesmoke" }} onClick={() => { histori.push("/Principal") }}>
                        Atras
                    </Button>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ boxShadow: "0px 0px 1px  black", marginTop: "4px", width: "100%" }}>
                        <form className={classes.form} autoComplete="off" onSubmit={enviarDatos}>
                            <Typography variant="subtitle1" align="center">
                                <strong >Geolocalización</strong>
                            </Typography>
                            <Grid container spacing={1} style={{ marginTop: "5px" }}>
                                <Grid item xs={12} sm={6} md={6} lg={3}>
                                    <Box>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            id="date"
                                            label="Fecha inicio"
                                            type="Date"
                                            name="fechainicio"
                                            format="yyyy-MM-dd"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={Data}
                                            value={datos.fechainicio}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={3}>
                                    <Box>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            id="date"
                                            label="Fecha final"
                                            type="Date"
                                            name="fechafinal"
                                            format="yyyy-MM-dd"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={Data}
                                            value={datos.fechafinal}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={4}>
                                    <Box >
                                        <Autocomplete
                                            options={getusuarios}
                                            getOptionLabel={(option) => option.nombre}
                                            id="responsable"
                                            name="responsable"
                                            onChange={(ev, value) => Personal(value)}
                                            renderInput={(params) => <TextField {...params} label="Persona" variant="outlined" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={2} >
                                    <Box >
                                        <Button fullWidth type="submit" style={{ backgroundColor: "green", color: "white", textAlign: "center", height: "55px" }}><SendIcon style={{ marginRight: "8px" }} />Consultar</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            {
                                getlocalizacion.length > 0 ?
                                    <Grid container spacing={1} style={{ marginTop: "5px" }}>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <TableContainer component={Paper}>
                                                <Table className={classes.table} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Fecha </TableCell>
                                                            <TableCell align="center">Inicial</TableCell>
                                                            <TableCell align="center">Final</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {getlocalizacion.map((row, index) => (
                                                            <TableRow key={row.idhoras}>
                                                                <TableCell component="th" scope="row">
                                                                    {row.fechainicial}
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <Popup modal closeOnDocumentClick={false} trigger={
                                                                        <IconButton aria-label="Localizacion inicial" title="Localización inicial" style={{ color: "blue" }}>
                                                                            <PinDropIcon style={{ height: "35px", width: "42px" }} />
                                                                        </IconButton>
                                                                    } position="center">
                                                                        {
                                                                            close => (
                                                                                <>
                                                                                    {row.actor}
                                                                                    <IconButton style={{ color: "red", align: "right" }} title="Localización final" aria-label="Abrir">
                                                                                        <CloseIcon onClick={() => (close())} style={{ height: "25px", width: "22px" }} />
                                                                                    </IconButton>
                                                                                    <Map
                                                                                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA0lkBP7GqYqHwaWQTR-Xh1J1gzyRRRjrY"
                                                                                        loadingElement={<div style={{ height: `100%` }} />}
                                                                                        containerElement={<div style={{ height: `500px` }} />}
                                                                                        mapElement={<div style={{ height: `100%` }} />}
                                                                                        latitud={row.latitudInicial} longitud={row.longitudInicial}
                                                                                    />

                                                                                </>
                                                                            )
                                                                        }
                                                                    </Popup>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    {
                                                                        row.latitudFinal !== "" ?
                                                                            <Popup modal closeOnDocumentClick={false} trigger={
                                                                                <IconButton aria-label="Localizacion final" title="Generar pdf" style={{ color: "green" }}>
                                                                                    <PersonPinCircleIcon style={{ height: "35px", width: "42px" }} />
                                                                                </IconButton>
                                                                            } position="center">
                                                                                {
                                                                                    close => (
                                                                                        <>
                                                                                            {row.actor}
                                                                                            <IconButton style={{ color: "red" }} title="Cerrar" aria-label="Abrir">
                                                                                                <CloseIcon onClick={() => (close())} style={{ height: "25px", width: "22px" }} />
                                                                                            </IconButton>
                                                                                            <Map
                                                                                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA0lkBP7GqYqHwaWQTR-Xh1J1gzyRRRjrY"
                                                                                                loadingElement={<div style={{ height: `100%` }} />}
                                                                                                containerElement={<div style={{ height: `500px` }} />}
                                                                                                mapElement={<div style={{ height: `100%` }} />}
                                                                                                latitud={row.latitudFinal} longitud={row.longitudFinal}
                                                                                            />

                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </Popup>
                                                                            : <strong style={{ color: "red" }}>N/A</strong>
                                                                    }

                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                    : ""
                            }

                            {/* <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "8px" }}>

                                <Map
                                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA0lkBP7GqYqHwaWQTR-Xh1J1gzyRRRjrY"
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: `500px` }} />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                    latitud={latitud} longitud={longitud}
                                />
                            </Grid> */}

                        </form>
                    </Grid>
                </Grid>
            </main>
        </div>
    );
}

Geolocalizacion.propTypes = {
    window: PropTypes.func,
};

export default Geolocalizacion;


