import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { Grid, Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Menu from "../Componentes/Menu";
import Headeditfuc from "../Componentes/Headeditfuc";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import CloseIcon from '@material-ui/icons/Close';
import { url } from "../Componentes/baseurl";
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';
import Archivos from '../Componentes/Archivos';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },

}));

function Formatoviaticos(props) {
    const [cargar, setCargar] = useState(false);
    const [value, setValue] = useState("Guardar");
    let histori = useHistory();
    let user = localStorage.getItem("user");
    let consecutivo = localStorage.getItem("consecutivo");
    if (user == null || user == "") {
        histori.push("/");
    }
    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <Menu />
    );

    const container = window !== undefined ? () => window().document.body : undefined;
    // carga de usuarios al auto complete 
    const MySwal = withReactContent(Swal);

    useEffect(() => {
        getInfofuc();
        getUsuarios();
    }, [])

    //usuarios 
    const [getusuarios, setGetusuarios] = useState([]);
    const getUsuarios = async () => {
        let idrol = localStorage.getItem("idrol");
        // debugger
        const res = await fetch(url + "Clogin", {
            method: "POST",
            body: JSON.stringify({ idrol: idrol, function: "getUsuarios" }),
            headers: {
                'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
                'Content-Type': 'application/json',
            },
        })
        const getusuarios = await res.json();
        setGetusuarios(getusuarios.Usuarios);
    }

    //consultainformacion guardada
    const [infoFuc, setInfoFuc] = useState([]);
    const [infoViati, setInfoViati] = useState([]);
    const getInfofuc = async () => {
        // debugger
        const res = await fetch(url + "Formatos/FormatoViaticos/Cviaticos", {
            method: "POST",
            body: JSON.stringify({ idfuc: localStorage.getItem('idfuc'), function: "getInfoFucViaticos" }),
            headers: {
                'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
                'Content-Type': 'application/json',
            },
        })
        const info = await res.json();
        setInfoFuc(info.infofuc);
        setInfoViati(info.viaticos);
    }

    // info valor recibido
    const [valorRecibido, setValorRecibido] = useState({
        fechaentrega: "",
        medio: "",
        origen: "",
        valor: "",

    });

    const GuardarValorRecibido = (ev) => {
        setValorRecibido({
            ...valorRecibido,
            [ev.target.name]: ev.target.value
        })
    }
    const [ArregloValorRecibido, setArregloValorRecibido] = useState([]);
    const [contador2, setContador2] = useState(1);
    const ArrValorRecibido = () => {
        const dataValorRecibido = {
            contador2: contador2,
            fechaentrega: valorRecibido.fechaentrega,
            medio: valorRecibido.medio,
            origen: valorRecibido.origen,
            valor: valorRecibido.valor,
            tipo: 1
        }
        var valid = true;
        var mesaje = "";
        if (dataValorRecibido.fechaentrega.length <= 0 || dataValorRecibido.fechaentrega == null) {
            valid = false;
            mesaje = mesaje + "<br> Fecha obligatoria."
        }
        if (dataValorRecibido.medio.length <= 0 || dataValorRecibido.medio == null) {
            valid = false;
            mesaje = mesaje + "<br> Medio obligatorio."
        }
        if (dataValorRecibido.origen.length <= 0 || dataValorRecibido.origen == null) {
            valid = false;
            mesaje = mesaje + "<br> Origen obligatorio."
        }
        if (dataValorRecibido.valor.length <= 0 || dataValorRecibido.valor == null) {
            valid = false;
            mesaje = mesaje + "<br> Valor obligatorio."
        }
        if (!valid) {
            mesajeerror(mesaje);
        } else {
            setArregloValorRecibido([...ArregloValorRecibido, dataValorRecibido]);
            setContador2(contador2 + 1);
        }
    }

    const deleteValor = (id) => {
        setArregloValorRecibido(ArregloValorRecibido.filter(contador2 => contador2.contador2 !== id))
    }

    //info Viaticos
    const [InfoViaticos, setInfoViaticos] = useState({
        fecharegistro: "",
        concepto: "",
        tercero: "",
        valorgasto: "",

    });

    const GuardarViaticos = (ev) => {
        setInfoViaticos({
            ...InfoViaticos,
            [ev.target.name]: ev.target.value
        })
    }
    const [ArregloViaticos, setArregloViaticos] = useState([]);
    const [contador, setContador] = useState(1);
    const ArrViaticos = () => {
        const dataViaticos = {
            contador: contador,
            fecharegistro: InfoViaticos.fecharegistro,
            concepto: InfoViaticos.concepto,
            tercero: InfoViaticos.tercero,
            valorgasto: InfoViaticos.valorgasto,
            tipo: 2,
        }
        var valid = true;
        var mesaje = "";
        if (dataViaticos.fecharegistro.length <= 0 || dataViaticos.fecharegistro == null) {
            valid = false;
            mesaje = mesaje + "<br> Fecha obligatoria."
        }
        if (dataViaticos.concepto.length <= 0 || dataViaticos.concepto == null) {
            valid = false;
            mesaje = mesaje + "<br> Concepto obligatorio."
        }
        if (dataViaticos.valorgasto.length <= 0 || dataViaticos.valorgasto == null) {
            valid = false;
            mesaje = mesaje + "<br> Valor obligatorio."
        }
        if (!valid) {
            mesajeerror(mesaje);
        } else {
            setArregloViaticos([...ArregloViaticos, dataViaticos]);
            setContador(contador + 1);
        }
    }

    const deleteD = (id) => {
        setArregloViaticos(ArregloViaticos.filter(contador => contador.contador !== id))
    }


    function mesajeerror(mesaje) {
        MySwal.fire({
            icon: 'info',
            title: '<div style="font-size: 24px">Verificación de datos</div>',
            html: '<div style="color: red; font-size: 18px">Existen datos incompletos:</div> <div style="font-size: 14px">' + mesaje + '</div>',
            confirmButtonColor: 'green',
            footer: '<div style="color: red">Todos los campos son obligatorios.</div>'
        })
    }

    function cancelar() {
        histori.push("/Principal");
    }

    //enviar datos 

    const [diligenciado, setDiligenciado] = useState("0");
    const [tesoreria, setTesoreria] = useState("0");
    const [gerencia, setGerencia] = useState("0");

    const Diligenciado = (value) => {
        setDiligenciado(value.idactor);
    }
    const Tesoreria = (value) => {
        setTesoreria(value.idactor);
    }
    const Gerencia = (value) => {
        setGerencia(value.idactor);
    }
    //envio datos
    const [data, setData] = useState({
        observaciones: "",
    });

    const inputRegistro = (ev) => {
        setData({
            ...data,
            [ev.target.name]: ev.target.value
        })
    }

    const enviarDatos = (ev) => {
        ev.preventDefault();
        setCargar(true);
        setValue("Por favor espere...");
        const desarrollofuc = localStorage.getItem("iddesarrollofuc");
        let funct = "";
        if (desarrollofuc == null || desarrollofuc == "") {
            funct = "saveFormatoViaticos";
        } else {
            funct = "UpdateFormatoViaticos";
        }
        const datos = {
            function: funct,
            document: localStorage.getItem('document'),
            image: localStorage.getItem('image'),
            arrValorRecibido: ArregloValorRecibido,
            arregloViaticos: ArregloViaticos,
            diligenciado: diligenciado,
            tesoreria: tesoreria,
            gerencia: gerencia,
            observaciones: data.observaciones,
            desarrollofuc: desarrollofuc,
            idtipofuc: localStorage.getItem('idtipofuc'),
            idfuc: localStorage.getItem('idfuc'),
        }
        var valid = true;
        var mesaje = "";
        if (!valid) {
            mesajeerror(mesaje);
            setCargar(false);
            setValue("Guardar");
        } else {
            fetch(url + "Formatos/FormatoViaticos/Cviaticos",
                {
                    method: "POST",
                    body: JSON.stringify(datos),
                    headers: {
                        'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
                        'Content-Type': 'application/json'
                    },
                })
                .then(respuesta => respuesta.json())
                .then((rta) => {
                    if (rta == 1) {
                        setCargar(false);
                        setValue("Guardar");
                        MySwal.fire({
                            position: 'center',
                            icon: 'info',
                            text: 'La información se guardo con exito.',
                            showConfirmButton: true,
                            confirmButtonColor: '#3085d6',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                histori.push("/Principal");
                            } else {
                                histori.push("/Principal");
                            }
                        })
                    } else {
                        setCargar(false);
                        setValue("Guardar");
                        MySwal.fire({
                            position: 'center',
                            icon: 'error',
                            text: 'Problemas al guardar información.' + rta,
                            showConfirmButton: true,
                            confirmButtonColor: '#3085d6',
                        });
                    }
                })
                .catch(error => {
                    setCargar(false);
                    setValue("Guardar");
                    MySwal.fire({
                        position: 'center',
                        icon: 'error',
                        text: 'Error en servidor ' + error.message,
                        showConfirmButton: true,
                        confirmButtonColor: '#3085d6',
                    });
                });
        }
    }


    //acordion 
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const [acorViticos, setAcorViticos] = useState(false);

    const handleViaticos = (panel) => (event, isExpanded) => {
        setAcorViticos(isExpanded ? panel : false);
    };
    //valid campos
    const [validGerencia, setValidaGerencia] = useState(localStorage.getItem('idrol') == '1' ? false : true);
    const [validTesoreria, setValidTesoreria] = useState(localStorage.getItem('idrol') == '8' ? false : true);
    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        Editar Fuc - {consecutivo}
                    </Typography>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Headeditfuc />
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} style={{ boxShadow: "0px 0px 1px  black", marginTop: "24px", width: "100%" }}>
                        <form className={classes.form} autoComplete="off" onSubmit={enviarDatos}>
                            <Grid container spacing={1} style={{ justifyContent: "center", display: "flex" }} >
                                <Grid item xs={12} sm={12} >
                                    <Typography variant="subtitle1" align="center">
                                        <strong style={{ marginLeft: "24px" }}>Valor recibido</strong>
                                    </Typography>
                                    <Grid container spacing={1} >
                                        <Grid item xs={12} sm={6} md={4} xl={6} lg={3}>
                                            <Box>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    id="date"
                                                    label="Fecha entrega."
                                                    type="Date"
                                                    name="fechaentrega"
                                                    format="yyyy-MM-dd"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={GuardarValorRecibido}
                                                    value={valorRecibido.fechaentrega}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} xl={6} lg={3}>
                                            <Box>
                                                <FormControl className={classes.formControl} autoComplete="off" fullWidth variant="outlined">
                                                    <InputLabel id="demo-simple-select-outlined-label">Medio</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="medio"
                                                        name="medio"
                                                        onChange={GuardarValorRecibido}
                                                        value={valorRecibido.medio}
                                                        label="Medio"
                                                    >
                                                        <MenuItem value="">
                                                            <em>Seleccionar</em>
                                                        </MenuItem>
                                                        <MenuItem value={1}>Efectivo</MenuItem>
                                                        <MenuItem value={2}>Transferencia bancaria</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} xl={6} lg={3}>
                                            <Box>
                                                <FormControl className={classes.formControl} autoComplete="off" fullWidth variant="outlined">
                                                    <InputLabel id="demo-simple-select-outlined-label">Origen</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="origen"
                                                        name="origen"
                                                        onChange={GuardarValorRecibido}
                                                        value={valorRecibido.origen}
                                                        label="Origen"
                                                    >
                                                        <MenuItem value="">
                                                            <em>Seleccionar</em>
                                                        </MenuItem>
                                                        <MenuItem value={1}>Efectivo tesoreria</MenuItem>
                                                        <MenuItem value={2}>Transferencia tecmmas</MenuItem>
                                                        <MenuItem value={3}>Efectivo cda</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} xl={6} lg={3}>
                                            <Box>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    id="valor"
                                                    label="Valor"
                                                    type="number"
                                                    name="valor"
                                                    onChange={GuardarValorRecibido}
                                                    value={valorRecibido.valor}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} xl={12} lg={12} style={{ justifyContent: "center", display: "flex", marginTop: "10px" }} >
                                            <Box >
                                                <Button onClick={() => (ArrValorRecibido())} style={{ backgroundColor: "green", color: "white", textAlign: "center" }}><PlaylistAddIcon style={{ marginRight: "8px" }} />Agregar</Button>
                                            </Box>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            {
                                                ArregloValorRecibido.map((item, index) => (
                                                    <Grid item xs={12} sm={6} md={6} lg={3} key={Math.random()}>
                                                        <Card style={{ boxShadow: "0px 0px 1px  black" }}>
                                                            <CardActionArea>
                                                                <CardContent>
                                                                    <Typography variant="body2" style={{ color: "black" }} component="p">
                                                                        <strong>Fecha: </strong>{item.fechaentrega} <br />
                                                                        <strong>Medio: </strong>{item.medio == 1 ? 'Efectivo' : 'Trasferencia bancaria'} <br />
                                                                        <strong>Origen: </strong>{item.origen == 1 ? 'Efectivo tesoreria' : item.origen == 2 ? 'Transferencia tecmmas' : 'Efectivo cda'} <br />
                                                                        <strong>valor: </strong>{"$" + item.valor}
                                                                    </Typography>
                                                                    <Grid item xs={12} sm={12} style={{ justifyContent: "center", display: "flex" }} >
                                                                        <IconButton aria-label="Eliminar" title="Eliminar" style={{ color: "red" }}>
                                                                            <CloseIcon style={{ height: "35px", width: "42px" }} onClick={(ev) => deleteValor(item.contador2)} />
                                                                        </IconButton>
                                                                    </Grid>
                                                                </CardContent>
                                                            </CardActionArea>
                                                        </Card>
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <Accordion expanded={acorViticos === 'panel1'} onChange={handleViaticos('panel1')}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                    style={{ backgroundColor: "#FCF3CF" }}
                                                >
                                                    <Typography style={{ color: "black" }}>Cantidad entregada</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                        <Grid container spacing={1}>
                                                            {
                                                                infoViati.map((item, index) => (
                                                                    item.tipo == 1 ?
                                                                        <Grid item xs={12} sm={6} md={6} lg={4}>
                                                                            <Card style={{ boxShadow: "0px 0px 1px  black" }} key={item.idviaticos}>
                                                                                <CardActionArea>
                                                                                    <CardContent>
                                                                                        <Typography gutterBottom style={{ fontSize: "15px" }}>
                                                                                            <strong>{item.nombreequipo}</strong>
                                                                                        </Typography>
                                                                                        <Typography variant="body2" style={{ color: "black" }} component="p">
                                                                                            <strong>Fecha entrega: </strong>{item.fecha}<br />
                                                                                            <strong>Medio: </strong>{item.medio} <br />
                                                                                            <strong>Origen: </strong>{item.origen} <br />
                                                                                            <strong>Valor: </strong>{"$ " + item.valor}
                                                                                        </Typography>
                                                                                    </CardContent>
                                                                                </CardActionArea>
                                                                            </Card>
                                                                        </Grid>
                                                                        : ""
                                                                ))
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                            <Typography variant="subtitle1" align="center">
                                                <strong style={{ marginLeft: "24px" }}>Legalización</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} xl={6} lg={3}>
                                            <Box>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    id="date"
                                                    label="Fecha"
                                                    type="Date"
                                                    name="fecharegistro"
                                                    format="yyyy-MM-dd"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={GuardarViaticos}
                                                    value={InfoViaticos.fecharegistro}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} xl={6} lg={3}>
                                            <Box>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    id="concepto"
                                                    label="Concepto"
                                                    type="text"
                                                    name="concepto"
                                                    onChange={GuardarViaticos}
                                                    value={InfoViaticos.concepto}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} xl={6} lg={3}>
                                            <Box>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    id="tercero"
                                                    label="Tercero"
                                                    type="text"
                                                    name="tercero"
                                                    onChange={GuardarViaticos}
                                                    value={InfoViaticos.tercero}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} xl={6} lg={3}>
                                            <Box>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    id="valorgasto"
                                                    label="Valor"
                                                    type="number"
                                                    name="valorgasto"
                                                    onChange={GuardarViaticos}
                                                    value={InfoViaticos.valorgasto}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} style={{ justifyContent: "center", display: "flex", marginTop: "10px" }} >
                                            <Box >
                                                <Button onClick={() => (ArrViaticos())} style={{ backgroundColor: "green", color: "white", textAlign: "center" }}><PlaylistAddIcon style={{ marginRight: "8px" }} />Agregar</Button>
                                            </Box>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            {
                                                ArregloViaticos.map((item, index) => (
                                                    <Grid item xs={12} sm={6} md={6} lg={3} key={Math.random()}>
                                                        <Card style={{ boxShadow: "0px 0px 1px  black" }}>
                                                            <CardActionArea>
                                                                <CardContent>
                                                                    <Typography variant="body2" color="black" component="p">
                                                                        <strong>Fecha: </strong>{item.fecharegistro} <br />
                                                                        <strong>Concepto: </strong>{item.concepto} <br />
                                                                        <strong>Tercero: </strong>{item.tercero} <br />
                                                                        <strong>valor: </strong>{"$" + item.valorgasto}
                                                                    </Typography>
                                                                    <Grid item xs={12} sm={12} style={{ justifyContent: "center", display: "flex" }} >
                                                                        <IconButton aria-label="Eliminar" title="Eliminar" style={{ color: "red" }}>
                                                                            <CloseIcon style={{ height: "35px", width: "42px" }} onClick={(ev) => deleteD(item.contador)} />
                                                                        </IconButton>
                                                                    </Grid>
                                                                </CardContent>
                                                            </CardActionArea>
                                                        </Card>
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                    style={{ backgroundColor: "#FCF3CF" }}
                                                >
                                                    <Typography style={{ color: "black" }}>Legalización guardada</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                        <Grid container spacing={1}>
                                                            {
                                                                infoViati.map((item, index) => (
                                                                    item.tipo == 2 ?
                                                                        <Grid item xs={12} sm={6} md={6} lg={4} key={item.idviaticos}>
                                                                            <Card style={{ boxShadow: "0px 0px 1px  black" }}>
                                                                                <CardActionArea>
                                                                                    <CardContent>
                                                                                        <Typography gutterBottom style={{ fontSize: "15px" }}>
                                                                                            <strong>{item.nombreequipo}</strong>
                                                                                        </Typography>
                                                                                        <Typography variant="body2" style={{ color: "black" }} component="p">
                                                                                            <strong>Fecha: </strong>{item.fecha}<br />
                                                                                            <strong>Concepto: </strong>{item.medio_concepto} <br />
                                                                                            <strong>Tercero: </strong>{item.origen_tercero} <br />
                                                                                            <strong>Valor: </strong>{"$" + item.valor}
                                                                                        </Typography>
                                                                                    </CardContent>
                                                                                </CardActionArea>
                                                                            </Card>
                                                                        </Grid>
                                                                        : ""
                                                                ))
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                            <Typography variant="subtitle1" align="center">
                                                <strong style={{ marginLeft: "24px" }}>Personal</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item item xs={12} sm={6} md={4} lg={4}  >
                                            <Box >
                                                <Autocomplete
                                                    options={getusuarios}
                                                    getOptionLabel={(option) => option.nombre}
                                                    id="diligenciado"
                                                    name="diligenciado"
                                                    onChange={(ev, value) => Diligenciado(value)}
                                                    renderInput={(params) => <TextField {...params} label="Diligenciado" margin="normal" variant="outlined" />}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item item xs={12} sm={6} md={4} lg={4}  >
                                            <Box >
                                                <Autocomplete
                                                    options={getusuarios}
                                                    getOptionLabel={(option) => option.nombre}
                                                    id="tesoreria"
                                                    name="tesoreria"
                                                    disabled={validTesoreria}
                                                    onChange={(ev, value) => Tesoreria(value)}
                                                    renderInput={(params) => <TextField {...params} label="Tesoreria" margin="normal" variant="outlined" />}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item item xs={12} sm={6} md={4} lg={4}  >
                                            <Box >
                                                <Autocomplete
                                                    options={getusuarios}
                                                    getOptionLabel={(option) => option.nombre}
                                                    id="gerencia"
                                                    name="gerencia"
                                                    disabled={validGerencia}
                                                    onChange={(ev, value) => Gerencia(value)}
                                                    renderInput={(params) => <TextField {...params} label="Gerencia" margin="normal" variant="outlined" />}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Box >
                                                <TextareaAutosize name="observaciones" id="observaciones" onChange={inputRegistro} style={{ width: "100%" }} aria-label="minimum height" rowsMin={3} placeholder="Ingrese las observaciones." />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Box >
                                                <Archivos />
                                            </Box>
                                        </Grid>
                                        {
                                            infoFuc.map(item => (
                                                <Grid item xs={12} sm={12} lg={12} style={{ boxShadow: "0px 0px 1px  black" }} key={Math.random()} >
                                                    <Grid container spacing={1} style={{ marginTop: "8px" }} >
                                                        <Grid item item xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
                                                            <strong style={{ marginRight: "8px" }}> Informacion guardada</strong>
                                                        </Grid>
                                                        <Grid item item xs={12} sm={6} md={4} lg={3}>
                                                            <strong style={{ marginRight: "8px" }}>Diligenciado: </strong><br></br>{item.diligenciado}
                                                        </Grid>
                                                        <Grid item item xs={12} sm={6} md={4} lg={3}>
                                                            <strong style={{ marginRight: "8px" }}>Tesoreria: </strong><br></br>{item.tesoreria}
                                                        </Grid>
                                                        <Grid item item xs={12} sm={6} md={4} lg={3}>
                                                            <strong style={{ marginRight: "8px" }}>Gerencia: </strong><br></br>{item.gerencia}
                                                        </Grid>
                                                        <Grid item item xs={12} sm={6} md={4} lg={3}>
                                                            <strong style={{ marginRight: "8px" }}>Observaciones: </strong><br></br>{item.observaciones}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ))
                                        }
                                        <Grid container spacing={2} style={{ marginTop: "8px" }} >
                                            <Grid item xs={6} sm={6}>
                                                <Box>
                                                    <Button fullWidth style={{ backgroundColor: "red", color: "white", textAlign: "center" }} onClick={cancelar}><CloseIcon style={{ marginRight: "8px" }} />Cancelar</Button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6} sm={6}>
                                                <Box>
                                                    <Button fullWidth type="submit" style={{ backgroundColor: "#3386FF", color: "white", textAlign: "center" }}> {cargar ? <CircularProgress style={{ marginRight: "10px", color: "white", }} /> : <SaveIcon style={{ marginRight: "8px" }} />} {value}</Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </main >
        </div >
    );
}

Formatoviaticos.propTypes = {
    window: PropTypes.func,
};

export default Formatoviaticos;
