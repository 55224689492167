import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { Grid, Box } from '@material-ui/core';
import Menu from "../Componentes/Menu";
import Headeditfuc from "../Componentes/Headeditfuc";
import TextField from '@material-ui/core/TextField';
import { url } from "../Componentes/baseurl";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Select from '@material-ui/core/Select'
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import CancelIcon from '@material-ui/icons/Cancel';
import Archivos from '../Componentes/Archivos';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CloseIcon from '@material-ui/icons/Close';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
}));

function Fuceditdesarrollo(props) {
  let histori = useHistory();
  let user = localStorage.getItem("user");
  let consecutivo = localStorage.getItem("consecutivo");
  if (user == null || user == "") {
    histori.push("/");
  }
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Menu />
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  // carga de usuarios al auto complete 
  const MySwal = withReactContent(Swal);
  const [getusuarios, setGetusuarios] = useState([]);
  useEffect(() => {
    try {
      document.createEvent("TouchEvent");
    }
    catch (e) {
    }
    getUsuarios();
    getinfoFuc();
  }, [])

  const getUsuarios = async () => {
    let idrol = localStorage.getItem("idrol");
    // debugger
    const res = await fetch(url + "Clogin", {
      method: "POST",
      body: JSON.stringify({ idrol: idrol, function: "getUsuarios" }),
      headers: {
        'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
        'Content-Type': 'application/json',
      },
    })
    const getusuarios = await res.json();
    setGetusuarios(getusuarios.Usuarios)
  }
  const [getInfofuc, setGetInfofuc] = useState([]);
  const getinfoFuc = async () => {
    let desarrollofuc = localStorage.getItem("iddesarrollofuc");
    // debugger
    const res = await fetch(url + "ProcesoDesarrollo/Cdesarrollo", {
      method: "POST",
      body: JSON.stringify({ function: "InfoFucDesarrolloActividades", iddesarrollofuc: desarrollofuc, idfuc: localStorage.getItem("idfuc") }),
      headers: {
        'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
        'Content-Type': 'application/json',
      },
    })
    const getInfofuc = await res.json();
    console.log(getInfofuc);
    setGetInfofuc(getInfofuc)
  }

  // renderizado de datos diseño o planeacion
  const [responsable, setResponsable] = useState("");
  const [disenoplaneacion, setDisenoplaneacion] = useState({
    fechainicial: "",
    fechafinal: "",
    dproceso: ""
  });

  const inputDiseno = (ev) => {
    setDisenoplaneacion({
      ...disenoplaneacion,
      [ev.target.name]: ev.target.value
    })
  }

  const [arrdisen, setArrdisen] = useState([]);
  const [contador, setContador] = useState(1);
  function dataDiseno(ev) {
    const datadiseno = {
      consecutivo: contador,
      tipoactividad: "Diseno",
      fechainicial: disenoplaneacion.fechainicial,
      fechafinal: disenoplaneacion.fechafinal,
      descripcion: disenoplaneacion.dproceso,
      responsableN: responsable.nombre,
      idresponsable: responsable.idactor
    }
    var valid = true;
    var mesaje = "";
    if (datadiseno.fechainicial.length <= 0 || datadiseno.fechainicial == null) {
      valid = false;
      mesaje = mesaje + "<br> La fecha inicial es obligatoria."
    }
    if (datadiseno.fechafinal.length <= 0 || datadiseno.fechafinal == null) {
      valid = false;
      mesaje = mesaje + "<br> La fecha final es obligatoria."
    }
    if (responsable.length <= 0 || responsable == null) {
      valid = false;
      mesaje = mesaje + "<br> Campo responsable es obligatorio."
    }
    if (datadiseno.descripcion.length <= 0 || datadiseno.descripcion == null) {
      valid = false;
      mesaje = mesaje + "<br> La descripcion es obligatoria."
    }
    if (!valid) {
      mesajeerror(mesaje);
    } else {
      setContador(contador + 1);
      setArrdisen([...arrdisen, datadiseno]);
    }
  }

  // function de entradas
  const [entradas, setEntradas] = useState({
    ttipoentrada: "",
    tresultado: "",
    tfecha: "",
    tdescripcion: ""
  });

  const inputEntradas = (ev) => {
    setEntradas({
      ...entradas,
      [ev.target.name]: ev.target.value
    })
    // console.log(ev.target.value);
  }

  const [arrentradas, setArrentradas] = useState([])
  const [tresponsable, setTresponsable] = useState("")
  const [contadorentra, setContadorentra] = useState(1);

  function dataEntradas() {
    const dataEntradas = {
      tconsecutivo: contadorentra,
      tipoactividad: "Registro",
      descripcion: entradas.tdescripcion,
      fechafinal: entradas.tfecha,
      tresponsable: tresponsable.nombre,
      idresponsable: tresponsable.idactor,
      tipoentrada: entradas.ttipoentrada,
      tiporesultado: entradas.tresultado
    }
    var valid = true;
    var mesaje = "";
    if (dataEntradas.tipoentrada.length <= 0 || dataEntradas.tipoentrada == null) {
      valid = false;
      mesaje = mesaje + "<br> Tipo de entrada es obligatorio."
    }
    if (tresponsable.length <= 0 || tresponsable == null) {
      valid = false;
      mesaje = mesaje + "<br> Campo responsable es obligatorio."
    }
    if (dataEntradas.tiporesultado.length <= 0 || dataEntradas.tiporesultado == null) {
      valid = false;
      mesaje = mesaje + "<br> Tipo de resultados es obligatorio."
    }
    if (dataEntradas.fechafinal.length <= 0 || dataEntradas.fechafinal == null) {
      valid = false;
      mesaje = mesaje + "<br> La fecha es obligatoria."
    }
    if (dataEntradas.descripcion.length <= 0 || dataEntradas.descripcion == null) {
      valid = false;
      mesaje = mesaje + "<br> La descripcion es obligatoria."
    }
    if (!valid) {
      mesajeerror(mesaje);
    } else {
      setContadorentra(contadorentra + 1);
      setArrentradas([...arrentradas, dataEntradas]);
    }
  }

  //eliminar
  const deleteD = (id, name) => {
    if (name === "diseno") {
      setArrdisen(arrdisen.filter(consecutivo => consecutivo.consecutivo !== id))
    } else {
      setArrentradas(arrentradas.filter(tconsecutivo => tconsecutivo.tconsecutivo !== id))
    }

  }

  //mesaje error en pantalla
  function mesajeerror(mesaje) {
    MySwal.fire({
      icon: 'info',
      title: '<div style="font-size: 24px">Verificación de datos</div>',
      html: '<div style="color: red; font-size: 18px">Existen datos incompletos:</div> <div style="font-size: 14px">' + mesaje + '</div>',
      confirmButtonColor: 'green',
      footer: '<div style="color: red">Todos los campos son obligatorios.</div>'
    })
  }

  //function cancelar
  function cancelar() {
    histori.push("/Principal");
  }

  //envio de informacion para guardado en base de datos
  const [cargar, setCargar] = useState(false);
  const [value, setValue] = useState("Guardar");
  const [data, setData] = useState({
    infoJefePista: "",
    infoVentas: "",
    infoSoporteTecnico: "",
    infoCompras: "",
    infoFabricacionTecnica: "",
    observaciones: ""
  });
  const inputData = (ev) => {
    setData({
      ...data,
      [ev.target.name]: ev.target.value
    })
    // console.log(ev.target.value)
  }
  const enviarDatos = (ev) => {
    setCargar(true);
    setValue("Por favor espere...");
    ev.preventDefault();
    const arr = arrdisen.concat(arrentradas);
    const desarrollofuc = localStorage.getItem("iddesarrollofuc");
    let fucntion = "";
    if (desarrollofuc == null || desarrollofuc == "") {
      fucntion = "SaveFucDesarrollo";
    } else {
      fucntion = "UpdateFucDesarrollo"
    }
    let datos = {
      function: fucntion,
      document: localStorage.getItem('document'),
      image: localStorage.getItem('image'),
      idfuc: localStorage.getItem("idfuc"),
      idtipofuc: localStorage.getItem("idtipofuc"),
      infoJefePista: data.infoJefePista,
      infoVentas: data.infoVentas,
      infoSoporteTecnico: data.infoSoporteTecnico,
      infoCompras: data.infoCompras,
      infoFabricacionTecnica: data.infoFabricacionTecnica,
      arr: arr,
      observaciones: data.observaciones,
      desarrollofuc: desarrollofuc,
    }
    fetch(url + "ProcesoDesarrollo/Cdesarrollo",
      {
        method: "POST",
        body: JSON.stringify(datos),
        headers: {
          'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
          'Content-Type': 'application/json'
        },
      })
      .then(respuesta => respuesta.json())
      .then((rta) => {
        console.log(rta);
        if (rta == 1) {
          setCargar(false);
          setValue("Guardar");
          MySwal.fire({
            position: 'center',
            icon: 'info',
            text: 'La información se guardo con exito.',
            showConfirmButton: true,
            confirmButtonColor: '#3085d6',
          }).then((result) => {
            if (result.isConfirmed) {
              histori.push("/Principal");
            } else {
              histori.push("/Principal");
            }
          })
        } else {
          setCargar(false);
          setValue("Guardar");
          MySwal.fire({
            position: 'center',
            icon: 'error',
            text: 'Problemas al guardar información.' + rta,
            showConfirmButton: true,
            confirmButtonColor: '#3085d6',
          });
        }
      })
      .catch(error => {
        setCargar(false);
        setValue("Guardar");
        MySwal.fire({
          position: 'center',
          icon: 'error',
          text: 'Error en servidor ' + error.message,
          showConfirmButton: true,
          confirmButtonColor: '#3085d6',
        });
      });
  }

  //acordion
  const [acorViticos, setAcorViticos] = useState(false);

  const handleViaticos = (panel) => (event, isExpanded) => {
    setAcorViticos(isExpanded ? panel : false);
  };
  const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Editar Fuc - {consecutivo}
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Headeditfuc />
        <Grid container spacing={2} >
          <Grid item xs={12} sm={12} style={{ boxShadow: "0px 0px 1px  black", marginTop: "24px", width: "100%" }}>
            <form className={classes.form} autoComplete="off" onSubmit={enviarDatos}>
              <Typography variant="subtitle1" align="center">
                <strong style={{ marginLeft: "24px" }}>Salidas esperadas</strong>
              </Typography>
              <Grid container spacing={1} >
                <Grid item xs={12} sm={6} md={3} lg={3} >
                  <Box>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      onChange={inputData}
                      value={data.infoJefePista}
                      id="infoJefePista"
                      label="Jefe de pista."
                      name="infoJefePista"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2} >
                  <Box>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      onChange={inputData}
                      value={data.infoVentas}
                      id="infoVentas"
                      label="Ventas."
                      name="infoVentas"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <Box>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      onChange={inputData}
                      value={data.infoSoporteTecnico}
                      id="infoSoporteTecnico"
                      label="Soporte tecnico."
                      name="infoSoporteTecnico"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <Box>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      onChange={inputData}
                      value={data.infoCompras}
                      id="infoCompras"
                      label="Compras."
                      name="infoCompras"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={10} md={3} lg={3}>
                  <Box>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      onChange={inputData}
                      value={data.infoFabricacionTecnica}
                      id="infoFabricacionTecnica"
                      label="Fabricacion en area tecnica."
                      name="infoFabricacionTecnica"
                    />
                  </Box>
                </Grid>

              </Grid>
              {
                getInfofuc.map(item => (
                  item.observaciones ?
                    <Grid item xs={12} sm={12} style={{ boxShadow: "0px 0px 1px  black" }} key={Math.random()} >
                      <Grid container spacing={1} >
                        <Grid item xs={12} sm={4} md={4} lg={3}>
                          <strong style={{ marginRight: "8px" }}>Info jefe de pista:</strong><br></br>{item.infoJefePista}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={2}>
                          <strong style={{ marginRight: "8px" }}>Info ventas:</strong><br></br>{item.infoVentas}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={2}>
                          <strong style={{ marginRight: "8px" }}>Info soporte tecnico:</strong><br></br>{item.infoSoporteTecnico}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={2}>
                          <strong style={{ marginRight: "8px" }}>Info compras:</strong><br></br>{item.infoCompras}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={3}>
                          <strong style={{ marginRight: "8px" }}>Info fabricación en area tecnica:</strong><br></br>{item.infoFabricacionTecnica}
                        </Grid>
                      </Grid>
                    </Grid>
                    : ""
                ))
              }
              <br></br>
              <Typography variant="subtitle1" align="center">
                <strong style={{ marginLeft: "24px" }}>Diseño y/o desarrollo</strong>
              </Typography>
              <Grid container spacing={1} >
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <Box>
                    <TextField
                      fullWidth
                      id="date"
                      variant="outlined"
                      label="Fecha inicio."
                      type="Date"
                      name="fechainicial"
                      format="yyyy-MM-dd"
                      onChange={inputDiseno}
                      value={disenoplaneacion.fechainicial}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ marginTop: "16px" }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <Box>
                    <TextField
                      fullWidth
                      id="date"
                      variant="outlined"
                      label="Fecha final."
                      type="Date"
                      name="fechafinal"
                      value={disenoplaneacion.fechafinal}
                      onChange={inputDiseno}
                      format="yyyy-MM-dd"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ marginTop: "16px" }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <Box >
                    <Autocomplete
                      options={getusuarios}
                      getOptionLabel={(option) => option.nombre}
                      id="responsable"
                      name="responsable"
                      onChange={(ev, value) => setResponsable(value)}
                      renderInput={(params) => <TextField {...params} label="Responsable" margin="normal" variant="outlined" />}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}  >
                  <Box >
                    <TextareaAutosize name="dproceso" id="dproceso" onChange={inputDiseno} style={{ width: "100%" }} aria-label="minimum height" rowsMin={3} placeholder="Describa el proceso realizado." />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={4}  >
                  <Box >
                    <Button fullWidth onClick={(ev) => dataDiseno()} style={{ backgroundColor: "green", color: "white", textAlign: "center", height: "50px" }}><PlaylistAddIcon style={{ marginRight: "8px" }} />Agregar</Button>
                  </Box>
                </Grid>
                {
                  arrdisen.map(item => (
                    <Grid item xs={12} sm={12} md={6} lg={4} key={Math.random()}>
                      <Card style={{ boxShadow: "0px 0px 1px  black" }}>
                        <CardActionArea>
                          <CardContent>
                            <Typography variant="body2" style={{ color: "black" }} component="p">
                              <strong>Creador: </strong>{item.responsableN}<br />
                              <strong>Fecha inicial: </strong>{item.fechainicial} <br />
                              <strong>Fecha final: </strong>{item.fechafinal} <br />
                              <strong>Descripcion: </strong>
                              <div>{item.descripcion}</div>
                            </Typography>
                            <Grid item xs={12} sm={12} style={{ justifyContent: "center", display: "flex" }} >
                              <IconButton aria-label="Eliminar" title="Eliminar" style={{ color: "red" }}>
                                <CloseIcon style={{ height: "35px", width: "42px" }} onClick={(ev) => deleteD(item.consecutivo, 'diseno')} />
                              </IconButton>
                            </Grid>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>

                  ))
                }
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Accordion expanded={acorViticos === 'panel1'} onChange={handleViaticos('panel1')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      style={{ backgroundColor: "#FCF3CF" }}
                    >
                      <Typography style={{ color: "black" }}>Diseño-Desarrollo</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container spacing={1}>
                          {
                            getInfofuc.map(item => (
                              item.tipoactividad == "Diseno" ?
                                <Grid item xs={12} sm={12} md={6} lg={4} key={Math.random()}>
                                  <Card style={{ boxShadow: "0px 0px 1px  black" }}>
                                    <CardActionArea>
                                      <CardContent>
                                        <Typography variant="body2" style={{ color: "black" }} component="p">
                                          <strong>Creador: </strong>{item.responsableN}<br />
                                          <strong>Fecha inicial: </strong>{item.fechainicial} <br />
                                          <strong>Fecha final: </strong>{item.fechafinal} <br />
                                          <strong>Descripcion: </strong>
                                          <div>{item.descripcion}</div>
                                        </Typography>
                                      </CardContent>
                                    </CardActionArea>
                                  </Card>
                                </Grid>
                                : ""
                            ))
                          }
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="subtitle1" align="center">
                    <strong style={{ marginLeft: "24px" }}>Registo información</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <Box >
                    <FormControl className={classes.formControl} autoComplete="off" fullWidth style={{ marginTop: "16px" }} variant="outlined">
                      <InputLabel id="demo-simple-select-outlined-label">Tipo de entrada</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="ttipoentrada"
                        name="ttipoentrada"
                        value={entradas.ttipoentrada}
                        onChange={inputEntradas}
                        label="Tipo de entrada"
                      >
                        <MenuItem value="">
                          <em>Seleccionar</em>
                        </MenuItem>
                        <MenuItem value={1}>Revision</MenuItem>
                        <MenuItem value={2}>Verificacion</MenuItem>
                        <MenuItem value={3}>Validacion</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <Box >
                    <Autocomplete
                      options={getusuarios}
                      getOptionLabel={(option) => option.nombre}
                      id="tresponsable"
                      name="tresponsable"
                      onChange={(ev, value) => setTresponsable(value)}
                      renderInput={(params) => <TextField {...params} label="Responsable" margin="normal" variant="outlined" />}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <Box >
                    <FormControl className={classes.formControl} autoComplete="off" fullWidth style={{ marginTop: "16px" }} variant="outlined">
                      <InputLabel id="demo-simple-select-outlined-label">Resultado</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="tresultado"
                        name="tresultado"
                        value={entradas.tresultado}
                        onChange={inputEntradas}
                        label="Resultado"
                      >
                        <MenuItem value="">
                          <em>Seleccionar</em>
                        </MenuItem>
                        <MenuItem value={1}>Exitoso</MenuItem>
                        <MenuItem value={2}>Fallido</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <Box>
                    <TextField
                      fullWidth
                      id="date"
                      variant="outlined"
                      label="Fecha registro"
                      type="Date"
                      name="tfecha"
                      value={entradas.tfecha}
                      onChange={inputEntradas}
                      format="yyyy-MM-dd"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ marginTop: "16px" }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <Box >
                    <TextareaAutosize name="tdescripcion" id="tdescripcion" onChange={inputEntradas} style={{ width: "100%" }} aria-label="minimum height" rowsMin={3} placeholder="Describa el proceso realizado." />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={4} >
                  <Box >
                    <Button fullWidth onClick={(ev) => dataEntradas()} style={{ backgroundColor: "green", color: "white", textAlign: "center", height: "50px" }}><PlaylistAddIcon style={{ marginRight: "8px" }} />Agregar</Button>
                  </Box>
                </Grid>
                {
                  arrentradas.map(item => (
                    <Grid item xs={12} sm={12} md={6} lg={3} key={Math.random()}>
                      <Card style={{ boxShadow: "0px 0px 1px  black" }}>
                        <CardActionArea>
                          <CardContent>
                            <Typography gutterBottom style={{ fontSize: "15px" }}>
                              <strong>{item.tipoentrada == 1 ? "Revisión" : item.tipoentrada == 2 ? "Verificación" : "Validación"}</strong>
                            </Typography>
                            <Typography variant="body2" style={{ color: "black" }} component="p">
                              <strong>Creador: </strong>{item.tresponsable}<br />
                              <strong>Resultado: </strong>{item.tiporesultado == 1 ? <label style={{ color: "green" }}><b>Exitoso</b></label> : <label style={{ color: "red" }}><b>Fallido</b></label>}<br />
                              <strong>Fecha: </strong>{item.fechafinal} <br />
                              <strong>Descripcion: </strong>
                              <div>{item.descripcion}</div>
                            </Typography>
                            <Grid item xs={12} sm={12} style={{ justifyContent: "center", display: "flex" }} >
                              <IconButton aria-label="Eliminar" title="Eliminar" style={{ color: "red" }}>
                                <CloseIcon style={{ height: "35px", width: "42px" }} onClick={(ev) => deleteD(item.tconsecutivo, 'entrada')} />
                              </IconButton>
                            </Grid>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))
                }
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      style={{ backgroundColor: "#FCF3CF" }}
                    >
                      <Typography style={{ color: "black" }}>Información guardada</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container spacing={1}>
                          {
                            getInfofuc.map(item => (
                              item.tipoactividad == "Registro" ?
                                <Grid item xs={12} sm={12} md={6} lg={4} key={Math.random()}>
                                  <Card style={{ boxShadow: "0px 0px 1px  black" }}>
                                    <CardActionArea>
                                      <CardContent>
                                        <Typography variant="body2" style={{ color: "black" }} component="p">
                                          <strong>Creador: </strong>{item.responsableN}<br />
                                          <strong>Resultado: </strong>{item.tiporesultado == 1 ? <label style={{ color: "green" }}><b>Exitoso</b></label> : <label style={{ color: "red" }}><b>Fallido</b></label>} <br />
                                          <strong>Fecha: </strong>{item.fechafinal} <br />
                                          <strong>Descripcion: </strong>
                                          <div>{item.descripcion}</div>
                                        </Typography>
                                      </CardContent>
                                    </CardActionArea>
                                  </Card>
                                </Grid>
                                : ""
                            ))
                          }
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12} sm={12} >
                  <Box >
                    <Archivos />
                  </Box>
                </Grid>

                {
                  getInfofuc.map(item => (
                    item.observaciones ?
                      <Grid item xs={12} sm={12} style={{ boxShadow: "0px 0px 1px  black", marginTop: "20px" }} key={Math.random()} >
                        <Grid container spacing={1} >
                          <Grid item xs={12} sm={3}>
                            <strong style={{ marginRight: "8px" }}>Observaciones registradas:</strong><br></br>{item.observaciones}
                          </Grid>
                        </Grid>
                      </Grid>
                      : ""
                  ))
                }
                
                <Grid item xs={12} sm={12}>
                  <Typography variant="subtitle1" align="center">
                    <strong style={{ marginLeft: "24px" }}>Registro de observaciones</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box >
                    <TextareaAutosize name="observaciones" id="observaciones" onChange={inputData} style={{ width: "100%" }} aria-label="minimum height" rowsMin={8} placeholder="Ingrese las observaciones." />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2} >
                <Grid item xs={6} sm={6}>
                  <Box>
                    <Button fullWidth style={{ backgroundColor: "red", color: "white", textAlign: "center" }} onClick={cancelar}><CancelIcon style={{ marginRight: "8px" }} />Cancelar</Button>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Box>
                    <Button fullWidth type="submit" style={{ backgroundColor: "#3386FF", color: "white", textAlign: "center" }}> {cargar ? <CircularProgress style={{ marginRight: "10px", color: "white", }} /> : <SaveIcon style={{ marginRight: "8px" }} />} {value}</Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </main >
    </div >
  );
}

Fuceditdesarrollo.propTypes = {
  window: PropTypes.func,
};

export default Fuceditdesarrollo;
