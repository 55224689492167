import React, { useState } from 'react';
import {
    GoogleMap,
    withScriptjs,
    withGoogleMap,
    Marker
} from "react-google-maps";
const Map = (props) => {
    return (
        <GoogleMap
            defaultZoom={20}
            defaultCenter={{ lat: +props.latitud, lng: +props.longitud }}
        >
            <Marker position={{ lat: +props.latitud, lng: +props.longitud }} />
        </GoogleMap>
    );
}
export default withScriptjs(
    withGoogleMap(
        Map
    ))