
import { Route, BrowserRouter as Router } from "react-router-dom";
import { Switch } from "react-router-dom";
import Login from "./Login/Login";
import Registro from "./Login/Registro";
import Principal from "./Login/Principal";
import Crearfuc from "./Componentes/Crearfuc";
import Fuceditdesarrollo from "./Odesarrollo/Fuceditdesarrollo";
import Fuceditventas from "./Oventas/Fuceditventas";
import Fueditmantenimiento from "./Omantenimiento/Fuceditmantenimiento";
import Fucalmacen from "./Oalmacen/Fucalmacen";
import Formatoviaticos from "./Formatos/Formatoviaticos";
import Cambiocontrasena from "./Login/Cambiocontrasena";
import Formatohoras from "./Formatos/Formatohoras";
import Geolocalizacion from "./Componentes/Geolocalizacion";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/Registro" component={Registro}></Route>
        <Route exact path="/Principal" component={Principal}></Route>
        <Route exact path="/Crearfuc" component={Crearfuc}></Route>
        <Route exact path="/Fuceditdesarrollo" component={Fuceditdesarrollo}></Route>
        <Route exact path="/Fuceditventas" component={Fuceditventas}></Route>
        <Route exact path="/Fueditmantenimiento" component={Fueditmantenimiento}></Route>
        <Route exact path="/Fucalmacen" component={Fucalmacen}></Route>
        <Route exact path="/Formatoviaticos" component={Formatoviaticos}></Route>
        <Route exact path="/Newcontra" component={Cambiocontrasena}></Route>
        <Route exact path="/Formatohoras" component={Formatohoras}></Route>
        <Route exact path="/Geolocalizacion" component={Geolocalizacion}></Route>
        <Route path="/" component={Login}></Route>
      </Switch>
    </Router>
  );
}

export default App;
