import React, { useState, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Links from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Select from '@material-ui/core/Select'
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import SaveIcon from '@material-ui/icons/Save';
import withReactContent from 'sweetalert2-react-content'
import CreateIcon from '@material-ui/icons/Create';
import { url } from '../Componentes/baseurl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import SignatureCanvas from 'react-signature-canvas';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import '../index.css';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            Todos Los Derechos Reservados <br></br>
            TECMMAS S.A.S {' '}
            {new Date().getFullYear()}
            {'.'}
            <br></br>
            <Links color="inherit" target="_blank" href="https://tecmmas.com/sitio/">
                www.tecmmas.com
            </Links>
            <br></br>
            <label style={{ color: "red" }}>Realese 1.0.1</label>
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    root: {
        flexGrow: 1,
    },
}));

export default function Registro() {
    const classes = useStyles();
    const [data, setData] = useState({
        nombre: "",
        tipod: "",
        idrol: "",
        direccion: "",
        numerod: "",
        telefono: "",
        email: "",
        username: "",
        password: "",
        tipoderegsitro: "",
    });


    const handleInputChange = (ev) => {
        setData({
            ...data,
            [ev.target.name]: ev.target.value
        })
        // console.log(ev.target.value)
    };

    // Carga los datos de rol en el select
    const [clien, setCliente] = useState([]);
    const [user, setUsuario] = useState([]);
    const [ciudad, setCiudad] = useState([]);


    useEffect(() => {
        getrol();
        getCiudades();
        getUsuarios();
    }, [])



    const getrol = async () => {
        // debugger
        const res = await fetch(url + "Clogin", {
            method: "POST",
            body: JSON.stringify({ function: "getrol" }),
            headers: {
                'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
                'Content-Type': 'application/json',
            },
        })
        const rolget = await res.json();
        console.log(rolget);
        setCliente(rolget.cliente);
        setUsuario(rolget.usuarios);
    }

    const getCiudades = async () => {
        // debugger
        const res = await fetch(url + "Clogin", {
            method: "POST",
            body: JSON.stringify({ function: "getCiudades" }),
            headers: {
                'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
                'Content-Type': 'application/json',
            },
        })
        const ciudades = await res.json();
        setCiudad(ciudades);
    }
    const [getusuarios, setGetusuarios] = useState([]);
    const [setidrepresentante, setIdrepresentante] = useState("");
    const getUsuarios = async () => {
        let idrol = localStorage.getItem("idrol");
        // debugger
        const res = await fetch(url + "Clogin", {
            method: "POST",
            body: JSON.stringify({ idrol: idrol, function: "getUsuarios" }),
            headers: {
                'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
                'Content-Type': 'application/json',
            },
        })
        const getusuarios = await res.json();
        setGetusuarios(getusuarios.Usuarios)
    }
    // Fin Carga los datos de rol en el select
    const [IdCiudad, setIdCiudad] = useState("");
    const MySwal = withReactContent(Swal)
    const [cargar, setCargar] = useState(false);
    const [value, setValue] = useState("Guardar");
    const enviarDatos = (ev) => {
        ev.preventDefault();
        setCargar(true);
        setValue("Por favor espere...");
        let datos = {
            nombre: data.nombre.toUpperCase(),
            tipod: data.tipod,
            idrol: data.idrol,
            // direccion: data.direccion.toUpperCase(),
            numerod: data.numerod,
            telefono: data.telefono,
            email: data.email,
            username: data.username,
            password: data.password,
            idciudad: IdCiudad.idciudad,
            idrepresentante: setidrepresentante.idactor,
            firma: firma,
            activo: 0,
            function: "registro",
        }
        let validate = true;
        let mesaje = "";
        if (data.tipoderegsitro.length <= 0 || data.tipoderegsitro == null) {
            mesaje = mesaje + '<br> Seleccione el tipo de registro.'
            validate = false;
        }
        if (data.tipoderegsitro !== 1 && data.tipoderegsitro !== 3) {
            if (setidrepresentante.length <= 0 || setidrepresentante == null) {
                mesaje = mesaje + '<br> Seleccione el representante.'
                validate = false;
            }
        }

        if (IdCiudad.length <= 0 || IdCiudad == null) {
            mesaje = mesaje + '<br> Seleccione la ciudad.'
            validate = false;
        }
        if (data.nombre.length <= 0 || data.nombre == null) {
            mesaje = mesaje + '<br> Nombres obligatorios.'
            validate = false;
        }
        if (data.idrol.length <= 0 || data.idrol == null) {
            mesaje = mesaje + '<br> Seleccione el rol.'
            validate = false;
        }
        if (data.tipod.length <= 0 || data.tipod == null) {
            mesaje = mesaje + '<br> Seleccione el tipo de documento.'
            validate = false;
        }
        if (data.numerod.length <= 0 || data.numerod == null) {
            mesaje = mesaje + '<br> Numero de documento obligatorio.'
            validate = false;
        }
        // if (data.direccion.length <= 0 || data.direccion == null) {
        //     mesaje = mesaje + '<br> Campo direccion es obligatorio.'
        //     validate = false;
        // }
        if (data.telefono.length <= 0 || data.telefono == null) {
            mesaje = mesaje + '<br> Campo telefono es obligatorio.'
            validate = false;
        }
        if (data.email.length <= 0 || data.email == null) {
            mesaje = mesaje + '<br> Campo email es obligatorio.'
            validate = false;
        }
        {
            if (data.tipoderegsitro !== 2) {
                if (firma.length <= 0 || firma == "") {
                    mesaje = mesaje + '<br> La firma es obligatoria.'
                    validate = false;
                }
            }
        }

        if (data.tipoderegsitro !== 2) {
            if (data.username.length < 5 || data.username == null) {
                mesaje = mesaje + '<br> Campo usuario es obligatorio. <br><label style= "color: red"> El usuario debe contar con minimo 5 caracteres.</label>'
                validate = false;
            }
            if (data.password.length < 6 || data.password == null) {
                mesaje = mesaje + '<br> Campo contraseña es obligatorio.<br><label style= "color: red"> La contraseña debe contar con minimo 6 caracteres.</label>'
                validate = false;
            }
        }
        if (!validate) {
            MySwal.fire({
                icon: 'info',
                title: '<div style="font-size: 24px">Verificación de datos</div>',
                html: '<div style="color: red; font-size: 18px">Existen datos incompletos:</div> <div style="font-size: 14px">' + mesaje + '</div>',
                confirmButtonColor: 'green',
                footer: '<div style="color: red">Todos los campos son obligatorios.</div>'
            })
            setCargar(false);
            setValue("Guardar");
        } else {
            fetch(url + "Clogin",
                {
                    method: "POST",
                    body: JSON.stringify(datos),
                    headers: {
                        'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
                        'Content-Type': 'application/json'
                    },
                })
                .then(respuesta => respuesta.json())
                .then((rta) => {
                    getUsuarios();
                    if (rta == 1) {
                        setFirma("");
                        setCargar(false);
                        setValue("Guardar");
                        ev.target.reset();
                        MySwal.fire({
                            position: 'center',
                            icon: 'info',
                            text: 'El usuario se registro con exito.',
                            showConfirmButton: true,
                            confirmButtonColor: '#3085d6',
                        })
                    }
                })
                .catch(error => {
                    console.log(error.message);
                    if (error) {
                        setCargar(false);
                        setValue("Guardar");
                        MySwal.fire({
                            position: 'center',
                            icon: 'error',
                            text: 'Problemas en el registro: ' + error.message,
                            showConfirmButton: true,
                            confirmButtonColor: '#3085d6',
                            // timer: 1500
                        })
                    }
                });
        }
    }

    //function de firma 





    const limFirma = useRef("")
    const limpiar = () => { limFirma.current.clear() }
    const saveFirma = () => {
        setFirma(limFirma.current.getTrimmedCanvas().toDataURL("image/pneg"))
    }
    const [firma, setFirma] = useState("");

    return (

        <Container maxWidth="lg" style={{ alignItems: "center" }}>
            <div className={classes.root}>
                <form autoComplete="off" onSubmit={enviarDatos}>
                    <img alt='some value' src={"http://sigc.tecmmas.com/Imagenes/logo.png"} width="25%" height="130px" />
                    <Grid container spacing={1} >
                        {/* <Grid container spacing={1}> */}
                        <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginTop: "15px" }}>
                            <FormControl variant="outlined" autoComplete="off" fullWidth >
                                <InputLabel id="demo-simple-select-outlined-label">Tipo de registro</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    name="tipoderegsitro"
                                    value={data.tipoderegsitro}
                                    onChange={handleInputChange}
                                    label="Tipo de registro"
                                >
                                    <MenuItem value="">
                                        <em>Seleccionar</em>
                                    </MenuItem>
                                    <MenuItem value={3}>Usuario</MenuItem>
                                    <MenuItem value={1}>Cliente</MenuItem>
                                    <MenuItem value={2}>Cda</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3}>

                            <FormControl variant="outlined" autoComplete="off" fullWidth style={{ marginTop: "15px" }}>
                                <InputLabel id="demo-simple-select-outlined-label">Tipo de rol</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    name="idrol"
                                    value={data.idrol}
                                    onChange={handleInputChange}
                                    label="Tipo de rol"
                                >
                                    <MenuItem value="" >
                                        <em>Seleccionar</em>
                                    </MenuItem>

                                    {
                                        data.tipoderegsitro == 3 ?
                                            user.map(item => (
                                                <MenuItem key={item.idrol} value={item.idrol}>{item.nombre}</MenuItem>
                                            ))
                                            :
                                            clien.map(item => (
                                                <MenuItem key={item.idrol} value={item.idrol}>{item.nombre}</MenuItem>
                                            ))

                                    }

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <FormControl variant="outlined" autoComplete="off" fullWidth style={{ marginTop: "15px" }}>
                                <InputLabel id="demo-simple-select-outlined-label">Tipo de documento</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    name="tipod"
                                    value={data.tipod}
                                    onChange={handleInputChange}
                                    label="Tipo de documento"
                                >
                                    <MenuItem value="">
                                        <em>Seleccionar</em>
                                    </MenuItem>
                                    <MenuItem value={"CC"}>Cedula Ciudadania</MenuItem>
                                    <MenuItem value={"NIT"}>Numero Identificación Tributaria</MenuItem>
                                    <MenuItem value={"CE"}>Cedula Extrangeria</MenuItem>
                                    <MenuItem value={"TI"}>Tarjeta de identidad</MenuItem>
                                    <MenuItem value={"NUIP"}>Registro Civil</MenuItem>
                                    <MenuItem value={"PAS"}>Pasaporte</MenuItem>
                                    <MenuItem value={"CD"}>Carnet Diplomático</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="numerod"
                                label="Documento"
                                type="number"
                                name="numerod"
                                // style={{ marginTop: "1px" }}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        {data.tipoderegsitro !== 1 || data.tipoderegsitro !== 3 ?
                            <Grid item xs={12} sm={6} md={6} lg={3} >
                                <Box >
                                    <Autocomplete
                                        options={getusuarios}
                                        getOptionLabel={(option) => option.nombre}
                                        id="representante"
                                        name="representante"
                                        onChange={(ev, value) => setIdrepresentante(value)}
                                        renderInput={(params) => <TextField {...params} label="Representante legal" margin="normal" variant="outlined" />}
                                    />
                                </Box>
                            </Grid>
                            : ""}
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <Box >
                                <Autocomplete
                                    options={ciudad}
                                    getOptionLabel={(option) => option.nombre}
                                    id="ciudad"
                                    name="ciudad"
                                    onChange={(ev, value) => setIdCiudad(value)}
                                    renderInput={(params) => <TextField {...params} label="Ciudad" margin="normal" variant="outlined" />}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="nombre"
                                label="Nombres y Apellidos"
                                name="nombre"
                                inputProps={{ style: { textTransform: "uppercase" } }}
                                onChange={handleInputChange}
                            />
                        </Grid>

                        {/* <Grid item xs={12} sm={6} md={6} lg={3}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="direccion"
                                label="Direccion"
                                type="text"
                                name="direccion"
                                fullWidth
                                // style={{ marginTop: "1px" }}
                                inputProps={{ style: { textTransform: "uppercase" } }}
                                onChange={handleInputChange}
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="telefono"
                                label="Telefono"
                                type="number"
                                name="telefono"
                                // style={{ marginTop: "1px" }}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="email"
                                label="Email"
                                type="email"
                                name="email"
                                // style={{ marginTop: "1px" }}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        {data.tipoderegsitro !== 2 ?
                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    name="username"
                                    label="Usuario"
                                    type="usuario"
                                    id="username"
                                    // style={{ marginTop: "1px" }}
                                    onChange={handleInputChange}
                                />
                            </Grid>

                            : ""}
                        {data.tipoderegsitro !== 2 ?
                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    name="password"
                                    label="Contraseña"
                                    type="password"
                                    id="password"
                                    // style={{ marginTop: "1px" }}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            : ""}

                    </Grid>
                    {
                        firma ?
                            <img alt='some value' style={{ marginTop: "10px", display: "block", border: "1px solid black", margin: "auto" }} width="100%" height="80px" src={firma} />
                            : ""
                    }
                    <Grid container spacing={3} style={{ marginTop: "5px" }}>
                        <Grid item xs={12} sm={6}>
                            <Popup modal closeOnDocumentClick={false} trigger={
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    style={{ backgroundColor: "green" }}
                                >
                                    <CreateIcon style={{ marginRight: "8px" }} />
                                    Agregar firma
                                </Button>
                            } position="center">
                                {
                                    close => (
                                        <>
                                            <SignatureCanvas penColor='black' ref={limFirma}
                                                canvasProps={{ className: 'firma' }} />
                                            <IconButton title="Cerrar" aria-label="Abrir" style={{ color: "red" }}>
                                                <CloseIcon onClick={() => (close())} style={{ height: "35px", width: "42px" }} />
                                            </IconButton>
                                            <IconButton style={{ color: "green" }} title="Limpiar campo" aria-label="Abrir">
                                                <AutorenewIcon onClick={() => (limpiar())} style={{ height: "35px", width: "42px" }} />
                                            </IconButton>
                                            <IconButton color="primary" title="Guardar firma" aria-label="Abrir">
                                                <SaveIcon onClick={() => (saveFirma(), close())} style={{ height: "35px", width: "42px" }} />
                                            </IconButton>
                                        </>
                                    )
                                }

                            </Popup>

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                {cargar ? <CircularProgress style={{ marginRight: "10px", color: "white", }} /> : <SaveIcon style={{ marginRight: "8px" }} />} {value}
                            </Button>
                        </Grid>
                        <Grid item xs>
                            <Link to={"/"} style={{ textDecoration: "none", color: "#3991DA" }}>
                                {"Regresar"}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={1}>
                <Copyright />
            </Box>
        </Container >

    );
}