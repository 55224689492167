import React, { useState, useEffect } from 'react';
import { Grid, Box } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { saveAs } from "file-saver";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useHistory } from "react-router-dom";
import { url, urlfile } from "./baseurl";



export default function Headeditfuc() {
    const MySwal = withReactContent(Swal);
    const [btn, setBtn] = useState(0);
    useEffect(() => {
        try {
            document.createEvent("TouchEvent");
            setBtn(1);
        }
        catch (e) {
            setBtn(0);
        }
        InfoFucs();
        getUsuarios();
    }, [])
    let histori = useHistory();
    const [getusuarios, setGetusuarios] = useState([]);
    const getUsuarios = async () => {
        let idrol = localStorage.getItem("idrol");
        // debugger
        const res = await fetch(url + "Clogin", {
            method: "POST",
            body: JSON.stringify({ idrol: idrol, function: "getUsuarios" }),
            headers: {
                'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
                'Content-Type': 'application/json',
            },
        })
        const getusuarios = await res.json();
        setGetusuarios(getusuarios.Usuarios)
    }
    const [responsable, setResponsable] = useState([]);
    const [documents, setDocument] = useState([]);
    const [image, setImage] = useState([]);
    const [asignados, setAsignados] = useState([]);
    const InfoFucs = async () => {
        // debugger
        const res = await fetch(url + "Cfuc", {
            method: "POST",
            body: JSON.stringify({ function: "infoFuc", idfuc: localStorage.getItem("idfuc"), consecutivo: localStorage.getItem("consecutivo"), idtipo: localStorage.getItem("idtipofuc") }),
            headers: {
                'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
                'Content-Type': 'application/json',
            },
        })
        const infoFuc = await res.json();
        setDocument(infoFuc.documentos);
        setImage(infoFuc.imagenes);
        setAsignados(infoFuc.fugasignados);
        setResponsable(infoFuc.responsable);
    }


    // funcion de descarga

    function download(nombre) {
        var tipo = localStorage.getItem("idtipofuc");
        var folder = "";
        switch (tipo) {
            case "1":
                folder = "ProcesoDireccionamientoEstrategico/";
                break;
            case "2":
            case "3":
                folder = "Procesoventas/";
                break;
            case "4":
                folder = "Procesofabricacionyensambleenareatecnica/";
                break;
            case "5":
                folder = "Procesoinstalacion/";
                break;
            case "6":
                folder = "Procesogarantia/";
                break;
            case "7":
                folder = "Procesosoportetecnico/";
                break;
            case "8":
                folder = "Procesomantenimiento/";
                break;
            case "9":
                folder = "Procesoalmacen/";
                break;
            case "10":
                folder = "Procesotalentohumano/";
                break;
            case "11":
                folder = "Procesodesarrollo/";
                break;
            case "12":
                folder = "Procesogestionintegral/";
                break;
            case "13":
                folder = "Procesogestionmantenimientolocativo/";
                break;
            case "14":
                folder = "Procesofabricacionyensambleenplata/";
                break;
            case "15":
                folder = "Formatoviaticos/";
                break;
            case "16":
                folder = "Formatohoras/";
                break;
            default:
                break;
        }
        var url = urlfile + folder + localStorage.getItem("consecutivo") + "/" + nombre
        saveAs(url, nombre);
    }

    // vista de imagenes 

    function viewImage(imagen) {
        MySwal.fire({
            imageUrl: imagen,
            imageHeight: 400,
            imageWidth: 480,
            imageAlt: 'image',
            confirmButtonColor: '#3085d6'
        })
    }

    // envio fuc
    const [asigFuc, setAsigFuc] = useState({
        fechalimite: "",
        descriAsigFuc: ""
    });
    const inputFucAsig = (ev) => {
        setAsigFuc({
            ...asigFuc,
            [ev.target.name]: ev.target.value
        })
    }

    const [fucasig, setFucasig] = useState("");

    const envioFuc = (ev) => {
        var valid = true;
        var mesaje = "";
        if (fucasig.length <= 0 || fucasig == null) {
            valid = false;
            mesaje = mesaje + "<br> Debe asignar el fuc a una persona."
        }
        if (asigFuc.fechalimite.length <= 0 || asigFuc.fechalimite == null) {
            valid = false;
            mesaje = mesaje + "<br> Debe asignar la fecha limite."
        }
        if (asigFuc.descriAsigFuc.length <= 0 || asigFuc.descriAsigFuc == null) {
            valid = false;
            mesaje = mesaje + "<br> Debe asignar una descripcion."
        }
        if (!valid) {
            mesajeerror(mesaje);
        } else {
            let datos = {
                function: "AsingFucNew",
                idfuc: localStorage.getItem("idfuc"),
                idasignador: localStorage.getItem("idactor"),
                idresponsableasignado: fucasig.idactor,
                fechalimite: asigFuc.fechalimite,
                descripcion: asigFuc.descriAsigFuc,
                retornado: 0,
            }
            fetch(url + "Cfuc",
                {
                    method: "POST",
                    body: JSON.stringify(datos),
                    headers: {
                        'Autorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ijg5NnNkYndmZTg3dmNzZGFmOTg0bmc4ZmdoMjRvMTI5MHIi.HraZ7y3eG3dGhKngzOWge-je8Y3lxZgldXjbRbcA7cA',
                        'Content-Type': 'application/json'
                    },
                })
                .then(respuesta => respuesta.json())
                .then((rta) => {
                    if (rta == 1) {
                        MySwal.fire({
                            position: 'center',
                            icon: 'info',
                            text: 'El Fuc se envio a ' + fucasig.nombre,
                            showConfirmButton: true,
                            confirmButtonColor: '#3085d6',
                        })
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }


    function mesajeerror(mesaje) {
        MySwal.fire({
            icon: 'info',
            title: '<div style="font-size: 24px">Verificación de datos</div>',
            html: '<div style="color: red; font-size: 18px">Existen datos incompletos:</div> <div style="font-size: 14px">' + mesaje + '</div>',
            confirmButtonColor: 'green',
        })
    }

    return (
        <Grid container spacing={2} >
            <Button variant="outlined" color="primary" style={{ backgroundColor: "#7296F6", color: "whitesmoke" }} onClick={() => { histori.push("/Principal") }}>
                Atras
            </Button>
            {documents.length > 0 ?
                <Grid item xs={12} sm={12} style={{ boxShadow: "0px 0px 1px  black", marginTop: "8px" }}>
                    <Typography variant="subtitle1"  >
                        <strong style={{ marginLeft: "24px" }}>Archivos Relacionados </strong>
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Nombre</TableCell>
                                    <TableCell align="center">Opciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    documents.map(item => (
                                        item.nombre ?
                                            <TableRow key={Math.random()} >
                                                <TableCell component="th" scope="row" align="center">
                                                    {item.nombre}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {btn == 0 ?
                                                        <IconButton onClick={() => { download(item.nombre) }} style={{ backgroundColor: "green", color: "white" }} aria-label="upload picture" component="span">
                                                            <VisibilityIcon />
                                                        </IconButton>
                                                        : <strong style={{ color: "red" }}>N/A</strong>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            : <TableRow key={Math.random()}></TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                : ""}
            {image.length > 0 ?
                <Grid item xs={12} sm={12} style={{ boxShadow: "0px 0px 1px  black", marginTop: "12px" }} >
                    <Typography variant="subtitle1"  >
                        <strong style={{ marginLeft: "24px" }}>Imagenes Tomadas</strong>
                    </Typography>
                    <br />
                    {
                        image.map(item => (
                            btn == 0 ?
                                item.imagen ? <img alt='some value' style={{ margin: "5px" }} key={item.imagen} src={item.imagen} onClick={() => (viewImage(item.imagen))} width="200px" height="200px" /> : ""
                                :
                                item.imagen ? <img alt='some value' style={{ margin: "5px" }} key={item.imagen} src={item.imagen} onClick={() => (viewImage(item.imagen))} width="80px" height="80px" /> : ""
                        ))
                    }
                </Grid>
                : ""}

            <Grid item xs={12} sm={12} style={{ boxShadow: "0px 0px 1px  black", marginTop: "12px" }} >
                <Grid container spacing={2} >
                    {asignados.length > 0 ?
                        <Grid item xs={12} sm={12} style={{ boxShadow: "0px 0px 1px  black" }}>
                            <Typography variant="subtitle1"  >
                                <strong style={{ marginLeft: "24px" }}>Personas asignadas al fug</strong>
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Nombre</TableCell>
                                            <TableCell align="center">Estado</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            asignados.map(item => (
                                                item.idresponsable ?
                                                    <TableRow key={Math.random()} >
                                                        <TableCell component="th" scope="row" align="center">
                                                            {item.idresponsable}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {item.retornado == 0 ? <div style={{ color: "red" }}>No retornado</div> : <div style={{ color: "green" }}>Retornado</div>}
                                                        </TableCell>
                                                    </TableRow>
                                                    : <TableRow key={Math.random()}></TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        : ""}
                    {
                        responsable.map(item => (
                            item.id == localStorage.getItem("idactor") ?
                                <><Grid item xs={12} sm={12} md={12} xl={12} lg={12}  >
                                    <Typography variant="subtitle1" align="left" style={{ marginTop: "20px", marginLeft: "8px" }} >
                                        {btn == 0 ?
                                            <strong >En esta sección puedes enviar este fuc a otra persona, siempre y cuando así lo necesites, esta persona podrá ver todo lo que se está desarrollando en el fuc y al finalizar lo deberá retornar para su posterior cierre.</strong>
                                            :
                                            <strong styel={{ size: "8px" }} >Asignar fuc a otra persona.</strong>
                                        }
                                    </Typography>
                                </Grid>
                                    <Grid item xs={12} sm={6} md={4} xl={6} lg={3}  >
                                        <Box >
                                            <Autocomplete
                                                options={getusuarios}
                                                getOptionLabel={(option) => option.idrol !== '17' ? option.nombre : ""}
                                                id="responsable"
                                                name="responsable"
                                                onChange={(ev, value) => setFucasig(value)}
                                                renderInput={(params) => <TextField {...params} label="Asignar Fuc" margin="normal" variant="outlined" />}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} xl={6} lg={3}  >
                                        <Box>
                                            <TextField
                                                fullWidth
                                                id="date"
                                                variant="outlined"
                                                label="Fecha limite."
                                                type="Date"
                                                name="fechalimite"
                                                format="yyyy-MM-dd"
                                                onChange={inputFucAsig}
                                                value={asigFuc.fechalimite}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                style={{ marginTop: "16px" }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} xl={6} lg={3}   >
                                        <TextareaAutosize name="descriAsigFuc" onChange={inputFucAsig} style={{ width: "100%", marginTop: "16px" }} aria-label="minimum height" rowsMin={3} placeholder="Describa el proceso." />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} xl={6} lg={3} >
                                        <Box >
                                            <Button fullWidth onClick={(ev) => (envioFuc())} type="submit" style={{ backgroundColor: "#D4AC0D", color: "white", textAlign: "center", height: "55px", marginTop: "15px" }}><ExitToAppIcon style={{ marginRight: "8px" }} />Asignar fuc</Button>
                                        </Box>
                                    </Grid>
                                </>
                                : ""
                        ))
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}
